import { images } from '.'; 
 
const galleryContent = [ 
    {
        id: 1,
        image: images.gallery1,
        title: 'Istanbul, Turkiye',
        style: '',
    },
    {
        id: 2,
        image: images.gallery2,
        title: 'Bali, Indonesia',
        style: '',
    },
    {
        id: 3,
        image: images.gallery3,
        title: 'Cabo San Lucas, Mexico',
        style: '',
    },
    {
        id: 4,
        image: images.gallery4,
        title: 'Rome, Italy',
        style: 'double',
    },
    {
        id: 5,
        image: images.gallery5,
        title: 'Cancun, Mexico',
        style: 'double',
    },
];

export default galleryContent;

