import React from 'react';

import { Gallery, Heading } from '../../../components';

const GallerySection = () => {
  return (
    <section> 
      {/* Section heading */}
      <Heading title={'our gallery'} span={"Most Incredible Places"} />
      {/* Gallery component */}
      <Gallery />
    </section>
  );
}

export default GallerySection;