/*****  
1)-useShopFilter: 
* A custom React Hook that facilitates filtering and pagination of a list of products or items based on selected category, tag, price range, and current page. 
* It returns an object with relevant state variables and functions.

2)-handleCategoryChange: Handles the change of selected category and updates the filtered products accordingly. It returns the matching products based on the selected category.
 * searchProductsByCategory: Filters the products/products based on the specified category.

3)-handleTag: Handles the change of selected tag and updates the filtered products accordingly. It returns the matching products based on the selected tag.
 * searchProductsByTag: Filters the products based on the specified tag.

4)-searchProductsByPrice: Filters the products/products based on the specified price range.

5)-handlePageChange: Handles the change of current page.

*****/ 

import { useEffect, useState } from 'react';

import { productsContent } from '../constants';

import { useParams } from 'react-router-dom';


const useShopFilter = (initialProducts, itemsPerPage) => {

  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedTag, setSelectedTag] = useState('');
  const [priceRange, setPriceRange] = useState({ min: 250, max: 750 });
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
 
  const { category, tag } = useParams();

  // Update selected category and tag when the route parameters change
  useEffect(() => { 
    setSelectedCategory(category || '');
    setSelectedTag(tag || '');
  }, [category, tag]);

  // Filter the initial products based on selected category and tag
  useEffect(() => {
    let matchingProducts = initialProducts;

    if (selectedCategory) {
      matchingProducts = handleCategoryChange(selectedCategory);
    }

    if (selectedTag) { 
      matchingProducts = handleTag(selectedTag);
    }

    setFilteredProducts(matchingProducts);
    setCurrentPage(1);

  }, [selectedCategory, selectedTag, initialProducts]);

 /**
   * Handle the change of selected category.
   * categoryTitle - The title of the selected category.
   * Return The filtered products based on the selected category.
   */
  const handleCategoryChange = (categoryTitle) => {
    const category = categoryTitle.toLowerCase();
    setSelectedCategory(category);
    if (category === "all") {
      return productsContent;
    } else {
      const matchingProducts = searchProductsByCategory(initialProducts, categoryTitle);
      return matchingProducts;
    }
  };

  /*****
   * Search products by category.
   * products - The array of shop items.
   * category - The category to search for.
   * Return The filtered products based on the category.
   */
  const searchProductsByCategory = (products, category) => {
    return products.filter((product) => product.category.find((c) => c.title === category));
  };

  /**
   * Handle the change of selected tag.
   * tagTitle - The title of the selected tag.
   * Return - The filtered products based on the selected tag.
   */
  const handleTag = (tagTitle) => {
    setSelectedTag(tagTitle.toLowerCase());
    const matchingProducts = searchProductsByTag(initialProducts, tagTitle);
    return matchingProducts;
  };

  /*****
   * Search products by tag.
   * products - The array of shop items.
   * tag - The tag to search for.
   * Return - The filtered products based on the tag.
   */
  const searchProductsByTag = (products, tag) => {
    return products.filter((product) => {
      return product.tags.some((tagObj) => tagObj.title.toLowerCase() === tag.toLowerCase());
    });
  };

    
  // Search products by price range. 
  const searchProductsByPrice = (products, range) => {
    return products.filter((product) => product.price >= range.min && product.price <= range.max);
  };

  // Get the products to display based on filtering 
  const displayProducts = filteredProducts.length > 0 ? filteredProducts : productsContent;
  const filteredProductsByPrice = searchProductsByPrice(displayProducts, priceRange);

  // Calculate start and end index based on current page and items per page
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  // Get the products to display on the current page
  const displayedProducts = filteredProductsByPrice.slice(startIndex, endIndex);

  // Calculate total pages
  const totalPages = Math.ceil(filteredProductsByPrice.length / itemsPerPage);

  // Handle the change of current page.
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return {
    selectedCategory,
    filteredProducts,
    priceRange,
    currentPage,
    handleCategoryChange,
    setPriceRange,
    displayedProducts,
    filteredProductsByPrice,
    totalPages,
    handlePageChange,
  };
};

export default useShopFilter;