import { images } from '../constants';

const home = [
    {  
        id: 1,
        image: images.home1,
        title: 'Leading the way in adventure',
        content: 'We believe everyone should travel Because travelling’s for everyone. We make vacations magical Making life the daring adventure you deserve.',
    },
    {
        id: 2,
        image: images.home2,
        title: 'Brace yourself for adventure',
        content: 'We believe everyone should travel Because travelling’s for everyone. We make vacations magical Making life the daring adventure you deserve.',   
    },
    { 
        id: 3,
        image: images.home3,
        title: 'We make vacations magical',
        content: 'We believe everyone should travel Because travelling’s for everyone. We make vacations magical Making life the daring adventure you deserve.',     
    },

];

export default home;