import { images } from ".";

const testimonials = [ 
    { 
        id: 1,
        image: images.testimonial1, 
        name: 'Eric Tindill', 
        title: 'Traveller',
        content: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Vel unde iure magni laboriosam. Laborum exercitationem cupiditate velit dolor dolorum? Voluptatem excepturi eaque eum voluptas voluptates, ex eligendi deserunt pariatur a?',
    },
    { 
        id: 2,
        image: images.testimonial2, 
        name: 'Karen Gunn', 
        title: 'Tourist',
        content: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Vel unde iure magni laboriosam. Laborum exercitationem cupiditate velit dolor dolorum? Voluptatem excepturi eaque eum voluptas voluptates, ex eligendi deserunt pariatur a?',
    },
    { 
        id: 3,
        image: images.testimonial3, 
        name: 'Tom Burtt', 
        title: 'Traveller',
        content: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Vel unde iure magni laboriosam. Laborum exercitationem cupiditate velit dolor dolorum? Voluptatem excepturi eaque eum voluptas voluptates, ex eligendi deserunt pariatur a?',
    },    { 
        id: 4,
        image: images.testimonial4, 
        name: 'Jennifer Turner', 
        title: 'Tourist',
        content: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Vel unde iure magni laboriosam. Laborum exercitationem cupiditate velit dolor dolorum? Voluptatem excepturi eaque eum voluptas voluptates, ex eligendi deserunt pariatur a?',
    },
];

export default testimonials;