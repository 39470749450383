import React from 'react';
import './Testimonials.css';

import { Heading } from '../../../components';
import { testiContent } from '../../../constants';

import { FaQuoteLeft } from 'react-icons/fa';

// Import Swiper core and required modules
import { Navigation, Pagination, Autoplay } from 'swiper';

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';



const Testimonials = () => {
  return (
    <section className='testimonials'>

      {/* Section heading */}
      <Heading title={'our testimonials'} span={`what our client's say`} />

      <div className='testimonial-slider'>

        <Swiper
          // Install Swiper modules
          modules={[Navigation, Pagination, Autoplay]}
          spaceBetween={220}
          loop={true}
          autoplay={{ delay: 4000, disableOnInteraction: false }}
          navigation
          pagination={{ clickable: true }}
        >
          {/* Map over the testiContent array to render each testimonial */}
          {testiContent.map((testimonial) => {

            // Destructure testimonial object
            const { id, image, content, name, title } = testimonial;

            return (
              <SwiperSlide className='testi-item' key={id}>

                {/* Testimonials Image */}
                <div className='image'>
                  <img src={image} alt="Testimonial-Pic" />
                  <FaQuoteLeft className='quote-left' />
                </div>

                {/* Testimonials Content */}
                <div className='content'>
                  <div className='text'>
                    <p>{content}</p> {/* Testimonial */}
                    <h3>{name}</h3> {/* Author name */}
                    <h5>{title}</h5> {/* Author title */}
                  </div>
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    </section>
  );
}

export default Testimonials;