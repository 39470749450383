import React from 'react';
import './Destination.css';

import { Link } from 'react-router-dom';



const Destination = (props) => {

  const { id, image, name, destination, totalTours } = props;

  return (
    <Link
      to={{
        pathname: '/Destinations/' + name,
      }}
      state={destination}
      className='destination-item' key={id}
    >
      {/* Destination image */} 
      <div className='image'>
        <img src={image} alt='Destination' /> 
      </div>

      {/* Destination Content */} 
      <div className='content'>
        <p>{totalTours} tours</p> {/* Total number of tours */}
        <h3 className='main-heading'>{name}</h3>  {/* Destination name */}
      </div>
      
    </Link>
  );
};

export default Destination;