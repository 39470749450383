import React from 'react';
import './TeamItem.css';

import { FaFacebookF, FaTwitter, FaInstagram, FaLinkedin } from 'react-icons/fa';



const TeamItem = (props) => {

    const { id, image, name, title, accounts } = props;

    return (
        <div className='team-item' key={id}>

            {/* Team member image Container */}
            <div className='image'> 
            
                {/* Team member Picture */}
                <img src={image} alt='Team-Pic' />

                {/* Social media icons */}
                <div className='icon-container'>
                    <a className='icon' href={accounts.facebook}><FaFacebookF /></a>
                    <a className='icon' href={accounts.linkedin}><FaLinkedin /></a>
                    <a className='icon' href={accounts.twitter}><FaTwitter /></a>
                    <a className='icon' href={accounts.instagram}><FaInstagram /></a>
                </div>

            </div>

            {/* Team member content */}
            <div className='content'>
                <h3>{name}</h3> {/* Team member name */}
                <p>{title}</p> {/* Team member title */}
            </div>
            
        </div>
    );
}

export default TeamItem;