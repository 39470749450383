import React from 'react';

import { Gallery, PageTitle } from '../../../components';



const GalleryPage = () => {
  return (
    <> 
      {/* Page Title */}
      <PageTitle title={'Gallery'} page={'Gallery'} />

      <section>
        {/* Gallery Component */}
        <Gallery />
      </section>
    </>
  );
}

export default GalleryPage;