import React from 'react';
import './ShopSidebar.css';

import { SidebarHeading, PriceFilter, Product } from '../../components';
import { productsContent } from '../../constants';
import { getPopular } from '../../utils/PopularResult';

import { FaCaretSquareRight } from 'react-icons/fa';
import { Link } from 'react-router-dom';



const ShopSidebar = ({ priceRange, setPriceRange }) => { 
  
  /***** 1-Category Names and Quantities *****/

  // Get the unique category names
  const categoryNames = ['all', ...new Set(productsContent.flatMap(product => product.category.map(category => category.title)))];

  // Count the quantity of products in each category
  const categoryCount = {};
  productsContent.forEach(product => {
    product.category.forEach(category => {
      const categoryName = category.title;
      categoryCount[categoryName] = categoryCount[categoryName] ? categoryCount[categoryName] + 1 : 1;
    });
  });

  // Calculate the total quantity of all products
  const totalQuantity = productsContent.length;

  // Extract the category name and quantity
  const categoryDetails = categoryNames.map(category => ({
    name: category,
    quantity: categoryCount[category] || 0,
  }));

  // Set the quantity for the "all" category
  categoryDetails[0].quantity = totalQuantity;

  /***** 2-Get popular products *****/
  const popularProducts = getPopular(productsContent);

  return (
    <div className='sidebar shop-sidebar'>

      {/* Category section */}
      <div className='category sidebar-item'>
        <SidebarHeading title='categories' />
        <div className='box-container'>
          {categoryDetails.map((category, index) => (
            <Link
              className='item'
              key={index}
              to={`/Shop/category/${category.name}`}
            >
              <span><FaCaretSquareRight className='icon' /> {category.name}</span>
              <p>({category.quantity})</p>
            </Link>
          ))}
        </div>
      </div>

      {/* Price Filter section */}
      <div className='filter sidebar-item'>
        <SidebarHeading title='price filter' />
        <PriceFilter
          priceRange={priceRange}
          setPriceRange={setPriceRange}
        />
      </div>

      {/* Popular Products section */}
      <div className='popular sidebar-item'>
        <SidebarHeading title='popular products' />
        <div className='box-container'>
          {popularProducts.map((product) => (
            <Product
              key={product.id}
              image={product.image}
              name={product.name}
              disprice={product.disprice}
              price={product.price}
              product={product}
            />
          ))}
        </div>
      </div>
    </div>
  )
}

export default ShopSidebar;