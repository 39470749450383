import React from 'react';
import './GalleryItem.css';

import { FaExpandAlt } from 'react-icons/fa';
 


const GalleryItem = ({id, image, title, style, popup}) => {

  return (
    <div className={`gallery-item ${style}`} key={id}>

      {/* Gallery image */} 
      <img src={image} alt={title} /> 

      {/* Gallery Content */} 
      <div className='content'>  
        <a onClick={() => {popup(id)}}><FaExpandAlt className='icon' /></a>  {/* Clicking on the icon, triggers the popup function */}
        <h3>{title}</h3> {/* Display the title of the gallery item */}
      </div>

    </div>  
  ) 

}

export default GalleryItem;