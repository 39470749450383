import React from 'react';
import './Cart.css';

import { PageTitle, Button, ShopSummary, ShopTitle, Quantity } from '../../../components';
import { cartItems, cartSummary } from '../../../constants';

import { RiDeleteBin5Fill } from 'react-icons/ri';
import { Link } from 'react-router-dom';



const Cart = () => {
  return (
    <>
      {/* Page title */}
      <PageTitle title={'cart'} page={'cart'} />

      <section className='cart'>
        <div className='shopping-cart'>
          <div className='container'>
            {/* Shop title */}
            <ShopTitle titles={['product', 'price', 'quantity', 'total', 'action']} />

            <div className='box-container'>
              {/* Render cart items */}
              {cartItems.map((item) => {
                return (
                  <div className='cart-item' key={item.id}>
                    <div className='box product'>
                      <img className='image' src={item.image} alt='' />
                      <div className='name'>{item.name}</div>
                    </div>
                    <div className='box price'>${item.price}</div>
                    <div className='box quantity'> 
                      <Quantity quantity={item.quantity} /> {/* Quantity component */}
                    </div>
                    <div className='box total'>${item.total}</div>
                    <div className='box action'>
                      <Link to='#'>
                        <RiDeleteBin5Fill className='icon delete' /> {/* Delete icon */}
                      </Link>
                    </div> 
                  </div>
                );
              })}
            </div> 
          </div>
        </div>

        <div className='cart-total'>
          <div className='summary-list'>
            {/* Shop summary */}
            <ShopSummary summary={cartSummary} />
          </div>
          {/* Proceed to checkout button */}
          <Button link={'checkout'} title={'proceed to checkout'} />
        </div>
      </section>
    </> 
  );
};

export default Cart;