import { images } from '../constants'; 

const blogs = [   
    {   
        id: 1, 
        image: images.blog1,
        category: 'Adventure Travel',
        date : 'may 10, 2023',
        admin: 'admin', 
        heading: 'Unforgettable Hiking Trails',
        content: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Lorem ipsum dolor sit amet.',
        important: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam possimus modi.Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam possimus modi.Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam possimus modi.',
        paragraph: {
            para1: 'Lorem Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Labore Repudiandae Aut Eum Rem Praesentium Doloremque Quos Perferendis Ipsa Sed Placeat Fugiat Minima Tenetur Facilis, Similique, Esse Molestias Harum. Illo, Obcaecati?',
            para2: 'Lorem Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Architecto, Vel Facere Iste Nobis Exercitationem Dicta? Sapiente Repellendus Quis In Sequi, Repellat, Maiores Architecto Debitis Reiciendis Odio Impedit Omnis Distinctio Ullam.',
            para3: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto, vel facere iste nobis exercitationem dicta? Sapiente repellendus quis in sequi, repellat, maiores architecto debitis reiciendis odio impedit omnis distinctio ullam.',
            para4: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto, vel facere iste nobis exercitationem dicta? Sapiente repellendus quis in sequi, repellat, maiores architecto debitis reiciendis odio impedit omnis distinctio ullam.',
        },
        gallery: [  
            {id: 1, image: images.blog1gallery1},
            {id: 2, image: images.blog1gallery2},
        ],
        tags: [ 
            {id: 1, title: 'Adventure'},
            {id: 2, title: 'Mountains'},
            {id: 3, title: 'Explore'},
        ],
        comments: [
            { 
                id: 1,
                image: images.blog1comment1,
                name: 'John Mills',
                date: 'june 1st, 2021',
                content: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Molestias sapiente ullam esse laudantium corporis pariatur. ',
                replies: [
                    {
                        id: 1,
                        image: images.blog1comment1A,
                        name: 'Sue Brown',
                        date: 'june 1st, 2021',
                        content: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Molestias sapiente ullam esse laudantium corporis pariatur. ',
                    },
                ],
            }, 
            {
                id: 2,
                image: images.blog1comment2,
                name: 'Ethna Rouse',
                date: 'june 1st, 2021',
                content: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Molestias sapiente ullam esse laudantium corporis pariatur. ',
            },
        ]
    },
    {   
        id: 2, 
        image: images.blog2,
        category: 'Family Travel',
        date : 'May 8, 2023',
        admin: 'admin',
        heading: 'Family travel tours',
        content: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam possimus modi.',
        important: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam possimus modi.Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam possimus modi.Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam possimus modi.',
        paragraph: {
            para1: 'Lorem Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Labore Repudiandae Aut Eum Rem Praesentium Doloremque Quos Perferendis Ipsa Sed Placeat Fugiat Minima Tenetur Facilis, Similique, Esse Molestias Harum. Illo, Obcaecati?',
            para2: 'Lorem Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Architecto, Vel Facere Iste Nobis Exercitationem Dicta? Sapiente Repellendus Quis In Sequi, Repellat, Maiores Architecto Debitis Reiciendis Odio Impedit Omnis Distinctio Ullam.',
            para3: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto, vel facere iste nobis exercitationem dicta? Sapiente repellendus quis in sequi, repellat, maiores architecto debitis reiciendis odio impedit omnis distinctio ullam.',
            para4: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto, vel facere iste nobis exercitationem dicta? Sapiente repellendus quis in sequi, repellat, maiores architecto debitis reiciendis odio impedit omnis distinctio ullam.',
        },
        gallery: [ 
            {id: 1, image: images.blog2gallery1},
            {id: 2, image: images.blog2gallery2},
        ],
        tags: [ 
            {id: 1, title: 'Family'},
            {id: 2, title: 'Vacation'},
            {id: 3, title: 'Journey'},
        ],
        comments: [
            {
                id: 1,
                image: images.blog2comment1,
                name: 'John Mills',
                date: 'june 1st, 2021',
                content: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Molestias sapiente ullam esse laudantium corporis pariatur. ',
            }, 
            {
                id: 2,
                image: images.blog2comment2,
                name: 'Ethna Rouse',
                date: 'june 1st, 2021',
                content: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Molestias sapiente ullam esse laudantium corporis pariatur. ',
            },
        ]
    },
    {   
        id: 3, 
        image: images.blog3,
        category: 'Destination Guides',
        date : 'Apr 15, 2023',
        admin: 'admin',
        heading: 'Discover turkey',
        content: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam possimus modi.',
        important: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam possimus modi.Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam possimus modi.Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam possimus modi.',
        paragraph: {
            para1: 'Lorem Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Labore Repudiandae Aut Eum Rem Praesentium Doloremque Quos Perferendis Ipsa Sed Placeat Fugiat Minima Tenetur Facilis, Similique, Esse Molestias Harum. Illo, Obcaecati?',
            para2: 'Lorem Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Architecto, Vel Facere Iste Nobis Exercitationem Dicta? Sapiente Repellendus Quis In Sequi, Repellat, Maiores Architecto Debitis Reiciendis Odio Impedit Omnis Distinctio Ullam.',
            para3: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto, vel facere iste nobis exercitationem dicta? Sapiente repellendus quis in sequi, repellat, maiores architecto debitis reiciendis odio impedit omnis distinctio ullam.',
            para4: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto, vel facere iste nobis exercitationem dicta? Sapiente repellendus quis in sequi, repellat, maiores architecto debitis reiciendis odio impedit omnis distinctio ullam.',
        },
        gallery: [ 
            {id: 1, image: images.blog3gallery1},
            {id: 2, image: images.blog3gallery2},
        ],
        tags: [ 
            {id: 1, title: 'Destination'},
            {id: 2, title: 'Explore'},
            {id: 3, title: 'Vacation'},
        ],
        comments: []
    },
    {   
        id: 4, 
        image: images.blog4,
        category: 'Travel Tips',
        date : 'Apr 25, 2023',
        admin: 'admin',
        heading: 'Packing And Beauty Tips',
        content: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam possimus modi.',
        important: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam possimus modi.Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam possimus modi.Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam possimus modi.',
        paragraph: {
            para1: 'Lorem Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Labore Repudiandae Aut Eum Rem Praesentium Doloremque Quos Perferendis Ipsa Sed Placeat Fugiat Minima Tenetur Facilis, Similique, Esse Molestias Harum. Illo, Obcaecati?',
            para2: 'Lorem Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Architecto, Vel Facere Iste Nobis Exercitationem Dicta? Sapiente Repellendus Quis In Sequi, Repellat, Maiores Architecto Debitis Reiciendis Odio Impedit Omnis Distinctio Ullam.',
            para3: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto, vel facere iste nobis exercitationem dicta? Sapiente repellendus quis in sequi, repellat, maiores architecto debitis reiciendis odio impedit omnis distinctio ullam.',
            para4: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto, vel facere iste nobis exercitationem dicta? Sapiente repellendus quis in sequi, repellat, maiores architecto debitis reiciendis odio impedit omnis distinctio ullam.',
        },
        gallery: [ 
            {id: 1, image: images.blog4gallery1},
            {id: 2, image: images.blog4gallery2},
        ],
        tags: [ 
            {id: 1, title: 'Explore'},
            {id: 2, title: 'Vacation'},
            {id: 3, title: 'Journey'},
        ],
        comments: [
            {
                id: 1,
                image: images.blog4comment1, 
                name: 'John Mills',
                date: 'june 1st, 2021',
                content: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Molestias sapiente ullam esse laudantium corporis pariatur. ',
                replies: [
                    {
                        id: 1,
                        image: images.blog4comment1A,
                        name: 'Ethna Rouse',
                        date: 'june 1st, 2021',
                        content: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Molestias sapiente ullam esse laudantium corporis pariatur. ',
                    },
                ],
            }, 
        ]
    },
    {   
        id: 5, 
        image: images.blog5,
        category: 'Food and Dining',
        date : 'Apr 5, 2023',
        admin: 'admin',
        heading: 'Exploring Street Food',
        content: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam possimus modi.',
        important: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam possimus modi.Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam possimus modi.Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam possimus modi.',
        paragraph: {
            para1: 'Lorem Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Labore Repudiandae Aut Eum Rem Praesentium Doloremque Quos Perferendis Ipsa Sed Placeat Fugiat Minima Tenetur Facilis, Similique, Esse Molestias Harum. Illo, Obcaecati?',
            para2: 'Lorem Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Architecto, Vel Facere Iste Nobis Exercitationem Dicta? Sapiente Repellendus Quis In Sequi, Repellat, Maiores Architecto Debitis Reiciendis Odio Impedit Omnis Distinctio Ullam.',
            para3: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto, vel facere iste nobis exercitationem dicta? Sapiente repellendus quis in sequi, repellat, maiores architecto debitis reiciendis odio impedit omnis distinctio ullam.',
            para4: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto, vel facere iste nobis exercitationem dicta? Sapiente repellendus quis in sequi, repellat, maiores architecto debitis reiciendis odio impedit omnis distinctio ullam.',
        },
        gallery: [ 
            {id: 1, image: images.blog5gallery1},
            {id: 2, image: images.blog5gallery2},
        ],
        tags: [ 
            {id: 1, title: 'Adventure'},
            {id: 2, title: 'Explore'},
            {id: 3, title: 'Culture'},
        ],
        comments: []
    },
    {   
        id: 6, 
        image: images.blog6,
        category: 'Adventure Travel',
        date : 'Mar 27, 2023', 
        admin: 'admin',
        heading: 'Road Trips',
        content: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam possimus modi.',
        important: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam possimus modi.Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam possimus modi.Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam possimus modi.',
        paragraph: {
            para1: 'Lorem Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Labore Repudiandae Aut Eum Rem Praesentium Doloremque Quos Perferendis Ipsa Sed Placeat Fugiat Minima Tenetur Facilis, Similique, Esse Molestias Harum. Illo, Obcaecati?',
            para2: 'Lorem Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Architecto, Vel Facere Iste Nobis Exercitationem Dicta? Sapiente Repellendus Quis In Sequi, Repellat, Maiores Architecto Debitis Reiciendis Odio Impedit Omnis Distinctio Ullam.',
            para3: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto, vel facere iste nobis exercitationem dicta? Sapiente repellendus quis in sequi, repellat, maiores architecto debitis reiciendis odio impedit omnis distinctio ullam.',
            para4: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto, vel facere iste nobis exercitationem dicta? Sapiente repellendus quis in sequi, repellat, maiores architecto debitis reiciendis odio impedit omnis distinctio ullam.',
        },
        gallery: [ 
            {id: 1, image: images.blog6gallery1},
            {id: 2, image: images.blog6gallery2},
        ],
        tags: [ 
            {id: 1, title: 'Adventure'},
            {id: 2, title: 'Journey'},
            {id: 3, title: 'Destination'},
        ],
        comments: [
            {
                id: 1,
                image: images.blog6comment1,
                name: 'John Mills',
                date: 'june 1st, 2021',
                content: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Molestias sapiente ullam esse laudantium corporis pariatur. ',
            }, 
            {
                id: 2,
                image: images.blog6comment2,
                name: 'Sue Brown',
                date: 'june 1st, 2021',
                content: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Molestias sapiente ullam esse laudantium corporis pariatur. ',
            },
        ]
    },
    {   
        id: 7,
        image: images.blog7,
        category: 'Family Travel',
        date : 'Mar 15, 2023',
        admin: 'admin',
        heading: 'Family Adventure Holidays',
        content: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam possimus modi.',
        important: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam possimus modi.Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam possimus modi.Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam possimus modi.',
        paragraph: {
            para1: 'Lorem Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Labore Repudiandae Aut Eum Rem Praesentium Doloremque Quos Perferendis Ipsa Sed Placeat Fugiat Minima Tenetur Facilis, Similique, Esse Molestias Harum. Illo, Obcaecati?',
            para2: 'Lorem Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Architecto, Vel Facere Iste Nobis Exercitationem Dicta? Sapiente Repellendus Quis In Sequi, Repellat, Maiores Architecto Debitis Reiciendis Odio Impedit Omnis Distinctio Ullam.',
            para3: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto, vel facere iste nobis exercitationem dicta? Sapiente repellendus quis in sequi, repellat, maiores architecto debitis reiciendis odio impedit omnis distinctio ullam.',
            para4: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto, vel facere iste nobis exercitationem dicta? Sapiente repellendus quis in sequi, repellat, maiores architecto debitis reiciendis odio impedit omnis distinctio ullam.',
        },
        gallery: [ 
            {id: 1, image: images.blog7gallery1},
            {id: 2, image: images.blog7gallery2},
        ],
        tags: [ 
            {id: 1, title: 'Family'},
            {id: 2, title: 'Adventure'},
            {id: 3, title: 'Mountains'},
        ],
        comments: [
            {
                id: 1,
                image: images.blog7comment1,
                name: 'John Mills',
                date: 'june 1st, 2021',
                content: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Molestias sapiente ullam esse laudantium corporis pariatur. ',
            }, 
        ]
    },
    {   
        id: 8, 
        image: images.blog8,
        category: 'Destination Guides',
        date : 'Mar 6, 2023',
        admin: 'admin',
        heading: 'The Ultimate indonesia Adventure',
        content: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam possimus modi.',
        important: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam possimus modi.Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam possimus modi.Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam possimus modi.',
        paragraph: {
            para1: 'Lorem Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Labore Repudiandae Aut Eum Rem Praesentium Doloremque Quos Perferendis Ipsa Sed Placeat Fugiat Minima Tenetur Facilis, Similique, Esse Molestias Harum. Illo, Obcaecati?',
            para2: 'Lorem Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Architecto, Vel Facere Iste Nobis Exercitationem Dicta? Sapiente Repellendus Quis In Sequi, Repellat, Maiores Architecto Debitis Reiciendis Odio Impedit Omnis Distinctio Ullam.',
            para3: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto, vel facere iste nobis exercitationem dicta? Sapiente repellendus quis in sequi, repellat, maiores architecto debitis reiciendis odio impedit omnis distinctio ullam.',
            para4: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto, vel facere iste nobis exercitationem dicta? Sapiente repellendus quis in sequi, repellat, maiores architecto debitis reiciendis odio impedit omnis distinctio ullam.',
        },
        gallery: [ 
            {id: 1, image: images.blog8gallery1},
            {id: 2, image: images.blog8gallery2},
        ],
        tags: [ 
            {id: 1, title: 'Adventure'},
            {id: 2, title: 'Vacation'},
            {id: 3, title: 'Culture'},
        ],
        comments: [
            {
                id: 1,
                image: images.blog8comment1,
                name: 'John Mills',
                date: 'june 1st, 2021',
                content: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Molestias sapiente ullam esse laudantium corporis pariatur. ',
            }, 
            {
                id: 2,
                image: images.blog8comment2,
                name: 'Sue Brown',
                date: 'june 1st, 2021',
                content: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Molestias sapiente ullam esse laudantium corporis pariatur. ',
            },
        ]
    },
    {   
        id: 9, 
        image: images.blog9,
        category: 'Travel Tips',
        date : 'Feb 22, 2023',
        admin: 'admin',
        heading: 'Essential Travel Safety Tips',
        content: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam possimus modi.',
        important: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam possimus modi.Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam possimus modi.Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam possimus modi.',
        paragraph: {
            para1: 'Lorem Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Labore Repudiandae Aut Eum Rem Praesentium Doloremque Quos Perferendis Ipsa Sed Placeat Fugiat Minima Tenetur Facilis, Similique, Esse Molestias Harum. Illo, Obcaecati?',
            para2: 'Lorem Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Architecto, Vel Facere Iste Nobis Exercitationem Dicta? Sapiente Repellendus Quis In Sequi, Repellat, Maiores Architecto Debitis Reiciendis Odio Impedit Omnis Distinctio Ullam.',
            para3: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto, vel facere iste nobis exercitationem dicta? Sapiente repellendus quis in sequi, repellat, maiores architecto debitis reiciendis odio impedit omnis distinctio ullam.',
            para4: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto, vel facere iste nobis exercitationem dicta? Sapiente repellendus quis in sequi, repellat, maiores architecto debitis reiciendis odio impedit omnis distinctio ullam.',
        },
        gallery: [ 
            {id: 1, image: images.blog9gallery1},
            {id: 2, image: images.blog9gallery2},
        ],
        tags: [ 
            {id: 1, title: 'Journey'},
            {id: 2, title: 'Explore'},
            {id: 3, title: 'Vacation'},
        ],
        comments: []
    },
    {   
        id: 10, 
        image: images.blog10,
        category: 'Food and Dining',
        date : 'Feb 7, 2023',
        admin: 'admin',
        heading: 'Trying Traditional Recipes',
        content: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam possimus modi.',
        important: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam possimus modi.Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam possimus modi.Lorem ipsum dolor sit, amet consectetur adipisicing elit. Nam possimus modi.',
        paragraph: {
            para1: 'Lorem Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Labore Repudiandae Aut Eum Rem Praesentium Doloremque Quos Perferendis Ipsa Sed Placeat Fugiat Minima Tenetur Facilis, Similique, Esse Molestias Harum. Illo, Obcaecati?',
            para2: 'Lorem Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Architecto, Vel Facere Iste Nobis Exercitationem Dicta? Sapiente Repellendus Quis In Sequi, Repellat, Maiores Architecto Debitis Reiciendis Odio Impedit Omnis Distinctio Ullam.',
            para3: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto, vel facere iste nobis exercitationem dicta? Sapiente repellendus quis in sequi, repellat, maiores architecto debitis reiciendis odio impedit omnis distinctio ullam.',
            para4: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto, vel facere iste nobis exercitationem dicta? Sapiente repellendus quis in sequi, repellat, maiores architecto debitis reiciendis odio impedit omnis distinctio ullam.',
        },
        gallery: [ 
            {id: 1, image: images.blog10gallery1},
            {id: 2, image: images.blog10gallery2},
        ],
        tags: [ 
            {id: 1, title: 'Culture'},
            {id: 2, title: 'Explore'},
            {id: 3, title: 'Vacation'},
        ],
        comments: [
            {
                id: 1,
                image: images.blog10comment1,
                name: 'John Mills',
                date: 'june 1st, 2021',
                content: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Molestias sapiente ullam esse laudantium corporis pariatur. ',
                replies: [
                    {
                        id: 1,
                        image: images.blog10comment1A,
                        name: 'Sue Brown',
                        date: 'june 1st, 2021',
                        content: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Molestias sapiente ullam esse laudantium corporis pariatur. ',
                    },
                ],
            }, 
            {
                id: 2,
                image: images.blog10comment2,
                name: 'Ethna Rouse',
                date: 'june 1st, 2021',
                content: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Molestias sapiente ullam esse laudantium corporis pariatur. ',
            },
        ]
    },
];

export default blogs;