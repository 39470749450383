import React from 'react';

import { Header, Footer, ScrollTop, ScrollToTop } from './components';
import { HomePage, AboutUs, TestimonialsPage, Faqs, BlogGrid, BlogList, BlogSingle, Login, Register, ForgotPwd, ContactPage, TeamPage, DestinationsPage, DestinationSingle, ToursPage, TourSingle, ShopGrid, ShopList, Cart, Checkout, GalleryPage, Contact, ProductSingle } from './sections';

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";



const App = () => {

  return (
    
    <div className='App'>

      <ScrollToTop />
      <Header />

      <Routes>

        {/*Home*/}
        <Route path='/' element={<HomePage />} />

        {/*About*/}
        <Route path='/About-us' element={<AboutUs />} /> 
        <Route path='/Faqs' element={<Faqs />} />   
        <Route path='/Team' element={<TeamPage />} />  
        <Route path='/Testimonials' element={<TestimonialsPage />} />

        {/*Pages*/}
        <Route path='/Gallery' element={<GalleryPage />} /> 
        <Route path='/Login' element={<Login />} />  
        <Route path='/Register' element={<Register />} /> 
        <Route path='/Forgot-pwd' element={<ForgotPwd />} /> 

        {/*Destination*/}
        <Route path='/Destinations' element={<DestinationsPage />} />  
        <Route path='/Destinations/:id' element={<DestinationSingle />} /> 
        <Route path='/Destinations/DestinationSingle' element={<DestinationSingle />} /> 

        {/*Tour*/}
        <Route path='/Tours' element={<ToursPage />} />   
        <Route path='/Tours/:id' element={<TourSingle />} />  
        <Route path='/Tours/TourSingle' element={<TourSingle />} /> 
  
        {/*Blogs*/}
        <Route path='/Blog-grid' element={<BlogGrid />} /> 
        <Route path='/Blogs/category/:category' element={<BlogList />} /> 
        <Route path='/Blogs/tag/:tag' element={<BlogList />} /> 
        <Route path='/Blogs/search/:search' element={<BlogList />} /> 
        <Route path='/Blog-list' element={<BlogList />} /> 
        <Route path='/Blogs/:id' element={<BlogSingle  />} />
        <Route path='/Blogs/Blog-Single' element={<BlogSingle />} />

        {/*Shop*/}
        <Route path='/Shop-grid' element={<ShopGrid />} />  
        <Route path='/Shop/category/:category' element={<ShopList />} /> 
        <Route path='/Shop/tag/:tag' element={<ShopList />} /> 
        <Route path='/Shop-list' element={<ShopList />} />
        <Route path='/Shop/:id' element={<ProductSingle />} />
        <Route path='/Shop/Product-Single' element={<ProductSingle />} /> 
        <Route path='/Cart' element={<Cart />} />
        <Route path='/Checkout' element={<Checkout />} />
 
        {/*Contact*/}
        <Route path='/contact-us' element={<Contact />} />

      </Routes>

      <Footer />
      <ScrollTop />
      
    </div>
  )
}

export default App;
