/*Global*/
import logo from '../assets/Logo/Logo.png';
import pageTitle from '../assets/PageTitle/PageTitle.jpg';

/*Home*/
import home1 from '../assets/Home/Home-1.jpg'; 
import home2 from '../assets/Home/Home-2.jpg';
import home3 from '../assets/Home/Home-3.jpg'; 

/*About*/ 
import about1 from '../assets/About/About-1.jpg';
import about2 from '../assets/About/About-2.jpg';

/*Background*/ 
import counterBg from '../assets/Background/Counter.jpg';
import DiscountBg from '../assets/Background/Discount.jpg';
import ToursBg from '../assets/Background/Tours.jpg';
import TestimonialsBg from '../assets/Background/Testimonials.jpg';

/*Team*/
import team1 from '../assets/Team/Team-1.jpg';
import team2 from '../assets/Team/Team-2.jpg';
import team3 from '../assets/Team/Team-3.jpg';
import team4 from '../assets/Team/Team-4.jpg';

/*Testimonials*/
import testimonial1 from '../assets/Testimonials/Pic-1.jpg';
import testimonial2 from '../assets/Testimonials/Pic-2.jpg';
import testimonial3 from '../assets/Testimonials/Pic-3.jpg';
import testimonial4 from '../assets/Testimonials/Pic-4.jpg';

/*FAQs*/
import FAQs from '../assets/FAQs/FAQs.png';

/*Gallery*/
import gallery1 from '../assets/Gallery/Gallery-1.jpg';
import gallery2 from '../assets/Gallery/Gallery-2.jpg';
import gallery3 from '../assets/Gallery/Gallery-3.jpg';
import gallery4 from '../assets/Gallery/Gallery-4.jpg';
import gallery5 from '../assets/Gallery/Gallery-5.jpg';

/*Destinations*/
import des1 from '../assets/Destination/Destinations/1.jpg';
import des1gallery1 from '../assets/Destination/Des-Gallery/1.jpg';
import des1gallery2 from '../assets/Destination/Des-Gallery/2.jpg';
import des1gallery3 from '../assets/Destination/Des-Gallery/3.jpg';
import des1gallery4 from '../assets/Destination/Des-Gallery/4.jpg';
import des1gallery5 from '../assets/Destination/Des-Gallery/5.jpg';
import des1gallery6 from '../assets/Destination/Des-Gallery/6.jpg';

import des1tour1 from '../assets/Destination/Tours/1.jpg';
import des1tour1gallery1 from '../assets/Destination/Tour-Gallery/1.jpg';
import des1tour1gallery2 from '../assets/Destination/Tour-Gallery/2.jpg';
import des1tour1gallery3 from '../assets/Destination/Tour-Gallery/3.jpg';
import des1tour1gallery4 from '../assets/Destination/Tour-Gallery/4.jpg';
import des1tour1gallery5 from '../assets/Destination/Tour-Gallery/5.jpg';
import des1tour1gallery6 from '../assets/Destination/Tour-Gallery/6.jpg';
import des1tour1review1 from '../assets/Destination/Reviews/Pic-1.jpg';
import des1tour1review2 from '../assets/Destination/Reviews/Pic-2.jpg';

import des1tour2 from '../assets/Destination/Tours/2.jpg';
import des1tour2gallery1 from '../assets/Destination/Tour-Gallery/1.jpg';
import des1tour2gallery2 from '../assets/Destination/Tour-Gallery/2.jpg';
import des1tour2gallery3 from '../assets/Destination/Tour-Gallery/3.jpg';
import des1tour2gallery4 from '../assets/Destination/Tour-Gallery/4.jpg';
import des1tour2gallery5 from '../assets/Destination/Tour-Gallery/5.jpg';
import des1tour2gallery6 from '../assets/Destination/Tour-Gallery/6.jpg';
import des1tour2review1 from '../assets/Destination/Reviews/Pic-1.jpg';
import des1tour2review2 from '../assets/Destination/Reviews/Pic-2.jpg';

import des1tour3 from '../assets/Destination/Tours/3.jpg';
import des1tour3gallery1 from '../assets/Destination/Tour-Gallery/1.jpg';
import des1tour3gallery2 from '../assets/Destination/Tour-Gallery/2.jpg';
import des1tour3gallery3 from '../assets/Destination/Tour-Gallery/3.jpg';
import des1tour3gallery4 from '../assets/Destination/Tour-Gallery/4.jpg';
import des1tour3gallery5 from '../assets/Destination/Tour-Gallery/5.jpg';
import des1tour3gallery6 from '../assets/Destination/Tour-Gallery/6.jpg';
import des1tour3review1 from '../assets/Destination/Reviews/Pic-1.jpg';
import des1tour3review2 from '../assets/Destination/Reviews/Pic-2.jpg';

import des2 from '../assets/Destination/Destinations/2.jpg';
import des2gallery1 from '../assets/Destination/Des-Gallery/1.jpg';
import des2gallery2 from '../assets/Destination/Des-Gallery/2.jpg';
import des2gallery3 from '../assets/Destination/Des-Gallery/3.jpg';
import des2gallery4 from '../assets/Destination/Des-Gallery/4.jpg';
import des2gallery5 from '../assets/Destination/Des-Gallery/5.jpg';
import des2gallery6 from '../assets/Destination/Des-Gallery/6.jpg';

import des2tour1 from '../assets/Destination/Tours/4.jpg';
import des2tour1gallery1 from '../assets/Destination/Tour-Gallery/1.jpg';
import des2tour1gallery2 from '../assets/Destination/Tour-Gallery/2.jpg';
import des2tour1gallery3 from '../assets/Destination/Tour-Gallery/3.jpg';
import des2tour1gallery4 from '../assets/Destination/Tour-Gallery/4.jpg';
import des2tour1gallery5 from '../assets/Destination/Tour-Gallery/5.jpg';
import des2tour1gallery6 from '../assets/Destination/Tour-Gallery/6.jpg';
import des2tour1review1 from '../assets/Destination/Reviews/Pic-1.jpg';
import des2tour1review2 from '../assets/Destination/Reviews/Pic-2.jpg';

import des2tour2 from '../assets/Destination/Tours/5.jpg';
import des2tour2gallery1 from '../assets/Destination/Tour-Gallery/1.jpg';
import des2tour2gallery2 from '../assets/Destination/Tour-Gallery/2.jpg';
import des2tour2gallery3 from '../assets/Destination/Tour-Gallery/3.jpg';
import des2tour2gallery4 from '../assets/Destination/Tour-Gallery/4.jpg';
import des2tour2gallery5 from '../assets/Destination/Tour-Gallery/5.jpg';
import des2tour2gallery6 from '../assets/Destination/Tour-Gallery/6.jpg';
import des2tour2review1 from '../assets/Destination/Reviews/Pic-1.jpg';
import des2tour2review2 from '../assets/Destination/Reviews/Pic-2.jpg';

import des2tour3 from '../assets/Destination/Tours/6.jpg';
import des2tour3gallery1 from '../assets/Destination/Tour-Gallery/1.jpg';
import des2tour3gallery2 from '../assets/Destination/Tour-Gallery/2.jpg';
import des2tour3gallery3 from '../assets/Destination/Tour-Gallery/3.jpg';
import des2tour3gallery4 from '../assets/Destination/Tour-Gallery/4.jpg';
import des2tour3gallery5 from '../assets/Destination/Tour-Gallery/5.jpg';
import des2tour3gallery6 from '../assets/Destination/Tour-Gallery/6.jpg';
import des2tour3review1 from '../assets/Destination/Reviews/Pic-1.jpg';
import des2tour3review2 from '../assets/Destination/Reviews/Pic-2.jpg';

import des3 from '../assets/Destination/Destinations/3.jpg';
import des3gallery1 from '../assets/Destination/Des-Gallery/1.jpg';
import des3gallery2 from '../assets/Destination/Des-Gallery/2.jpg';
import des3gallery3 from '../assets/Destination/Des-Gallery/3.jpg';
import des3gallery4 from '../assets/Destination/Des-Gallery/4.jpg';
import des3gallery5 from '../assets/Destination/Des-Gallery/5.jpg';
import des3gallery6 from '../assets/Destination/Des-Gallery/6.jpg';

import des3tour1 from '../assets/Destination/Tours/7.jpg';
import des3tour1gallery1 from '../assets/Destination/Tour-Gallery/1.jpg';
import des3tour1gallery2 from '../assets/Destination/Tour-Gallery/2.jpg';
import des3tour1gallery3 from '../assets/Destination/Tour-Gallery/3.jpg';
import des3tour1gallery4 from '../assets/Destination/Tour-Gallery/4.jpg';
import des3tour1gallery5 from '../assets/Destination/Tour-Gallery/5.jpg';
import des3tour1gallery6 from '../assets/Destination/Tour-Gallery/6.jpg';
import des3tour1review1 from '../assets/Destination/Reviews/Pic-1.jpg';
import des3tour1review2 from '../assets/Destination/Reviews/Pic-2.jpg';

import des3tour2 from '../assets/Destination/Tours/8.jpg';
import des3tour2gallery1 from '../assets/Destination/Tour-Gallery/1.jpg';
import des3tour2gallery2 from '../assets/Destination/Tour-Gallery/2.jpg';
import des3tour2gallery3 from '../assets/Destination/Tour-Gallery/3.jpg';
import des3tour2gallery4 from '../assets/Destination/Tour-Gallery/4.jpg';
import des3tour2gallery5 from '../assets/Destination/Tour-Gallery/5.jpg';
import des3tour2gallery6 from '../assets/Destination/Tour-Gallery/6.jpg';
import des3tour2review1 from '../assets/Destination/Reviews/Pic-1.jpg';
import des3tour2review2 from '../assets/Destination/Reviews/Pic-2.jpg';

import des3tour3 from '../assets/Destination/Tours/9.jpg';
import des3tour3gallery1 from '../assets/Destination/Tour-Gallery/1.jpg';
import des3tour3gallery2 from '../assets/Destination/Tour-Gallery/2.jpg';
import des3tour3gallery3 from '../assets/Destination/Tour-Gallery/3.jpg';
import des3tour3gallery4 from '../assets/Destination/Tour-Gallery/4.jpg';
import des3tour3gallery5 from '../assets/Destination/Tour-Gallery/5.jpg';
import des3tour3gallery6 from '../assets/Destination/Tour-Gallery/5.jpg';
import des3tour3review1 from '../assets/Destination/Reviews/Pic-1.jpg';
import des3tour3review2 from '../assets/Destination/Reviews/Pic-2.jpg';

import des4 from '../assets/Destination/Destinations/4.jpg';
import des4gallery1 from '../assets/Destination/Des-Gallery/1.jpg';
import des4gallery2 from '../assets/Destination/Des-Gallery/2.jpg';
import des4gallery3 from '../assets/Destination/Des-Gallery/3.jpg';
import des4gallery4 from '../assets/Destination/Des-Gallery/4.jpg';
import des4gallery5 from '../assets/Destination/Des-Gallery/5.jpg';
import des4gallery6 from '../assets/Destination/Des-Gallery/6.jpg';

import des4tour1 from '../assets/Destination/Tours/10.jpg';
import des4tour1gallery1 from '../assets/Destination/Tour-Gallery/1.jpg';
import des4tour1gallery2 from '../assets/Destination/Tour-Gallery/2.jpg';
import des4tour1gallery3 from '../assets/Destination/Tour-Gallery/3.jpg';
import des4tour1gallery4 from '../assets/Destination/Tour-Gallery/4.jpg';
import des4tour1gallery5 from '../assets/Destination/Tour-Gallery/5.jpg';
import des4tour1gallery6 from '../assets/Destination/Tour-Gallery/6.jpg';
import des4tour1review1 from '../assets/Destination/Reviews/Pic-1.jpg';
import des4tour1review2 from '../assets/Destination/Reviews/Pic-2.jpg';

import des4tour2 from '../assets/Destination/Tours/11.jpg';
import des4tour2gallery1 from '../assets/Destination/Tour-Gallery/1.jpg';
import des4tour2gallery2 from '../assets/Destination/Tour-Gallery/2.jpg';
import des4tour2gallery3 from '../assets/Destination/Tour-Gallery/3.jpg';
import des4tour2gallery4 from '../assets/Destination/Tour-Gallery/4.jpg';
import des4tour2gallery5 from '../assets/Destination/Tour-Gallery/5.jpg';
import des4tour2gallery6 from '../assets/Destination/Tour-Gallery/6.jpg';
import des4tour2review1 from '../assets/Destination/Reviews/Pic-1.jpg';
import des4tour2review2 from '../assets/Destination/Reviews/Pic-2.jpg';

import des4tour3 from '../assets/Destination/Tours/12.jpg';
import des4tour3gallery1 from '../assets/Destination/Tour-Gallery/1.jpg';
import des4tour3gallery2 from '../assets/Destination/Tour-Gallery/2.jpg';
import des4tour3gallery3 from '../assets/Destination/Tour-Gallery/3.jpg';
import des4tour3gallery4 from '../assets/Destination/Tour-Gallery/4.jpg';
import des4tour3gallery5 from '../assets/Destination/Tour-Gallery/5.jpg';
import des4tour3gallery6 from '../assets/Destination/Tour-Gallery/6.jpg';
import des4tour3review1 from '../assets/Destination/Reviews/Pic-1.jpg';
import des4tour3review2 from '../assets/Destination/Reviews/Pic-2.jpg';

import des5 from '../assets/Destination/Destinations/5.jpg';
import des5gallery1 from '../assets/Destination/Des-Gallery/1.jpg';
import des5gallery2 from '../assets/Destination/Des-Gallery/2.jpg';
import des5gallery3 from '../assets/Destination/Des-Gallery/3.jpg';
import des5gallery4 from '../assets/Destination/Des-Gallery/4.jpg';
import des5gallery5 from '../assets/Destination/Des-Gallery/5.jpg';
import des5gallery6 from '../assets/Destination/Des-Gallery/6.jpg';

import des5tour1 from '../assets/Destination/Tours/13.jpg';
import des5tour1gallery1 from '../assets/Destination/Tour-Gallery/1.jpg';
import des5tour1gallery2 from '../assets/Destination/Tour-Gallery/2.jpg';
import des5tour1gallery3 from '../assets/Destination/Tour-Gallery/3.jpg';
import des5tour1gallery4 from '../assets/Destination/Tour-Gallery/4.jpg';
import des5tour1gallery5 from '../assets/Destination/Tour-Gallery/5.jpg';
import des5tour1gallery6 from '../assets/Destination/Tour-Gallery/6.jpg';
import des5tour1review1 from '../assets/Destination/Reviews/Pic-1.jpg';
import des5tour1review2 from '../assets/Destination/Reviews/Pic-2.jpg';

import des5tour2 from '../assets/Destination/Tours/14.jpg';
import des5tour2gallery1 from '../assets/Destination/Tour-Gallery/1.jpg';
import des5tour2gallery2 from '../assets/Destination/Tour-Gallery/2.jpg';
import des5tour2gallery3 from '../assets/Destination/Tour-Gallery/3.jpg';
import des5tour2gallery4 from '../assets/Destination/Tour-Gallery/4.jpg';
import des5tour2gallery5 from '../assets/Destination/Tour-Gallery/5.jpg';
import des5tour2gallery6 from '../assets/Destination/Tour-Gallery/6.jpg';
import des5tour2review1 from '../assets/Destination/Reviews/Pic-1.jpg';
import des5tour2review2 from '../assets/Destination/Reviews/Pic-2.jpg';

import des5tour3 from '../assets/Destination/Tours/15.jpg';
import des5tour3gallery1 from '../assets/Destination/Tour-Gallery/1.jpg';
import des5tour3gallery2 from '../assets/Destination/Tour-Gallery/2.jpg';
import des5tour3gallery3 from '../assets/Destination/Tour-Gallery/3.jpg';
import des5tour3gallery4 from '../assets/Destination/Tour-Gallery/4.jpg';
import des5tour3gallery5 from '../assets/Destination/Tour-Gallery/5.jpg';
import des5tour3gallery6 from '../assets/Destination/Tour-Gallery/6.jpg';
import des5tour3review1 from '../assets/Destination/Reviews/Pic-1.jpg';
import des5tour3review2 from '../assets/Destination/Reviews/Pic-2.jpg';

import des6 from '../assets/Destination/Destinations/6.jpg';
import des6gallery1 from '../assets/Destination/Des-Gallery/1.jpg';
import des6gallery2 from '../assets/Destination/Des-Gallery/2.jpg';
import des6gallery3 from '../assets/Destination/Des-Gallery/3.jpg';
import des6gallery4 from '../assets/Destination/Des-Gallery/4.jpg';
import des6gallery5 from '../assets/Destination/Des-Gallery/5.jpg';
import des6gallery6 from '../assets/Destination/Des-Gallery/6.jpg';

import des6tour1 from '../assets/Destination/Tours/16.jpg';
import des6tour1gallery1 from '../assets/Destination/Tour-Gallery/1.jpg';
import des6tour1gallery2 from '../assets/Destination/Tour-Gallery/2.jpg';
import des6tour1gallery3 from '../assets/Destination/Tour-Gallery/3.jpg';
import des6tour1gallery4 from '../assets/Destination/Tour-Gallery/4.jpg';
import des6tour1gallery5 from '../assets/Destination/Tour-Gallery/5.jpg';
import des6tour1gallery6 from '../assets/Destination/Tour-Gallery/6.jpg';
import des6tour1review1 from '../assets/Destination/Reviews/Pic-1.jpg';
import des6tour1review2 from '../assets/Destination/Reviews/Pic-2.jpg';

import des6tour2 from '../assets/Destination/Tours/17.jpg';
import des6tour2gallery1 from '../assets/Destination/Tour-Gallery/1.jpg';
import des6tour2gallery2 from '../assets/Destination/Tour-Gallery/2.jpg';
import des6tour2gallery3 from '../assets/Destination/Tour-Gallery/3.jpg';
import des6tour2gallery4 from '../assets/Destination/Tour-Gallery/4.jpg';
import des6tour2gallery5 from '../assets/Destination/Tour-Gallery/5.jpg';
import des6tour2gallery6 from '../assets/Destination/Tour-Gallery/6.jpg';
import des6tour2review1 from '../assets/Destination/Reviews/Pic-1.jpg';
import des6tour2review2 from '../assets/Destination/Reviews/Pic-2.jpg';

import des6tour3 from '../assets/Destination/Tours/18.jpg';
import des6tour3gallery1 from '../assets/Destination/Tour-Gallery/1.jpg';
import des6tour3gallery2 from '../assets/Destination/Tour-Gallery/2.jpg';
import des6tour3gallery3 from '../assets/Destination/Tour-Gallery/3.jpg';
import des6tour3gallery4 from '../assets/Destination/Tour-Gallery/4.jpg';
import des6tour3gallery5 from '../assets/Destination/Tour-Gallery/5.jpg';
import des6tour3gallery6 from '../assets/Destination/Tour-Gallery/6.jpg';
import des6tour3review1 from '../assets/Destination/Reviews/Pic-1.jpg';
import des6tour3review2 from '../assets/Destination/Reviews/Pic-2.jpg';

import des7 from '../assets/Destination/Destinations/7.jpg';
import des7gallery1 from '../assets/Destination/Des-Gallery/1.jpg';
import des7gallery2 from '../assets/Destination/Des-Gallery/2.jpg';
import des7gallery3 from '../assets/Destination/Des-Gallery/3.jpg';
import des7gallery4 from '../assets/Destination/Des-Gallery/4.jpg';
import des7gallery5 from '../assets/Destination/Des-Gallery/5.jpg';
import des7gallery6 from '../assets/Destination/Des-Gallery/6.jpg';

import des7tour1 from '../assets/Destination/Tours/19.jpg';
import des7tour1gallery1 from '../assets/Destination/Tour-Gallery/1.jpg';
import des7tour1gallery2 from '../assets/Destination/Tour-Gallery/2.jpg';
import des7tour1gallery3 from '../assets/Destination/Tour-Gallery/3.jpg';
import des7tour1gallery4 from '../assets/Destination/Tour-Gallery/4.jpg';
import des7tour1gallery5 from '../assets/Destination/Tour-Gallery/5.jpg';
import des7tour1gallery6 from '../assets/Destination/Tour-Gallery/6.jpg';
import des7tour1review1 from '../assets/Destination/Reviews/Pic-1.jpg';
import des7tour1review2 from '../assets/Destination/Reviews/Pic-2.jpg';

import des7tour2 from '../assets/Destination/Tours/20.jpg';
import des7tour2gallery1 from '../assets/Destination/Tour-Gallery/1.jpg';
import des7tour2gallery2 from '../assets/Destination/Tour-Gallery/2.jpg';
import des7tour2gallery3 from '../assets/Destination/Tour-Gallery/3.jpg';
import des7tour2gallery4 from '../assets/Destination/Tour-Gallery/4.jpg';
import des7tour2gallery5 from '../assets/Destination/Tour-Gallery/5.jpg';
import des7tour2gallery6 from '../assets/Destination/Tour-Gallery/6.jpg';
import des7tour2review1 from '../assets/Destination/Reviews/Pic-1.jpg';
import des7tour2review2 from '../assets/Destination/Reviews/Pic-2.jpg';

import des7tour3 from '../assets/Destination/Tours/21.jpg';
import des7tour3gallery1 from '../assets/Destination/Tour-Gallery/1.jpg';
import des7tour3gallery2 from '../assets/Destination/Tour-Gallery/2.jpg';
import des7tour3gallery3 from '../assets/Destination/Tour-Gallery/3.jpg';
import des7tour3gallery4 from '../assets/Destination/Tour-Gallery/4.jpg';
import des7tour3gallery5 from '../assets/Destination/Tour-Gallery/5.jpg';
import des7tour3gallery6 from '../assets/Destination/Tour-Gallery/6.jpg';
import des7tour3review1 from '../assets/Destination/Reviews/Pic-1.jpg';
import des7tour3review2 from '../assets/Destination/Reviews/Pic-2.jpg';

import des8 from '../assets/Destination/Destinations/8.jpg';
import des8gallery1 from '../assets/Destination/Des-Gallery/1.jpg';
import des8gallery2 from '../assets/Destination/Des-Gallery/2.jpg';
import des8gallery3 from '../assets/Destination/Des-Gallery/3.jpg';
import des8gallery4 from '../assets/Destination/Des-Gallery/4.jpg';
import des8gallery5 from '../assets/Destination/Des-Gallery/5.jpg';
import des8gallery6 from '../assets/Destination/Des-Gallery/6.jpg';

import des8tour1 from '../assets/Destination/Tours/22.jpg';
import des8tour1gallery1 from '../assets/Destination/Tour-Gallery/1.jpg';
import des8tour1gallery2 from '../assets/Destination/Tour-Gallery/2.jpg';
import des8tour1gallery3 from '../assets/Destination/Tour-Gallery/3.jpg';
import des8tour1gallery4 from '../assets/Destination/Tour-Gallery/4.jpg';
import des8tour1gallery5 from '../assets/Destination/Tour-Gallery/5.jpg';
import des8tour1gallery6 from '../assets/Destination/Tour-Gallery/6.jpg';
import des8tour1review1 from '../assets/Destination/Reviews/Pic-1.jpg';
import des8tour1review2 from '../assets/Destination/Reviews/Pic-2.jpg';

import des8tour2 from '../assets/Destination/Tours/23.jpg';
import des8tour2gallery1 from '../assets/Destination/Tour-Gallery/1.jpg';
import des8tour2gallery2 from '../assets/Destination/Tour-Gallery/2.jpg';
import des8tour2gallery3 from '../assets/Destination/Tour-Gallery/3.jpg';
import des8tour2gallery4 from '../assets/Destination/Tour-Gallery/4.jpg';
import des8tour2gallery5 from '../assets/Destination/Tour-Gallery/5.jpg';
import des8tour2gallery6 from '../assets/Destination/Tour-Gallery/6.jpg';
import des8tour2review1 from '../assets/Destination/Reviews/Pic-1.jpg';
import des8tour2review2 from '../assets/Destination/Reviews/Pic-2.jpg';

import des8tour3 from '../assets/Destination/Tours/24.jpg';
import des8tour3gallery1 from '../assets/Destination/Tour-Gallery/1.jpg';
import des8tour3gallery2 from '../assets/Destination/Tour-Gallery/2.jpg';
import des8tour3gallery3 from '../assets/Destination/Tour-Gallery/3.jpg';
import des8tour3gallery4 from '../assets/Destination/Tour-Gallery/4.jpg';
import des8tour3gallery5 from '../assets/Destination/Tour-Gallery/5.jpg';
import des8tour3gallery6 from '../assets/Destination/Tour-Gallery/6.jpg';
import des8tour3review1 from '../assets/Destination/Reviews/Pic-1.jpg';
import des8tour3review2 from '../assets/Destination/Reviews/Pic-2.jpg';

/*Blogs*/
import blog1 from '../assets/Blog/Blogs/1.jpg';
import blog1gallery1 from '../assets/Blog/Gallery/1.jpg';
import blog1gallery2 from '../assets/Blog/Gallery/2.jpg';
import blog1comment1 from '../assets/Blog/Comments/Pic-1.jpg';
import blog1comment1A from '../assets/Blog/Comments/Pic-2.jpg';
import blog1comment2 from '../assets/Blog/Comments/Pic-3.jpg';

import blog2 from '../assets/Blog/Blogs/2.jpg';
import blog2gallery1 from '../assets/Blog/Gallery/1.jpg';
import blog2gallery2 from '../assets/Blog/Gallery/2.jpg';
import blog2comment1 from '../assets/Blog/Comments/Pic-1.jpg';
import blog2comment2 from '../assets/Blog/Comments/Pic-2.jpg';

import blog3 from '../assets/Blog/Blogs/3.jpg';
import blog3gallery1 from '../assets/Blog/Gallery/1.jpg';
import blog3gallery2 from '../assets/Blog/Gallery/2.jpg';

import blog4 from '../assets/Blog/Blogs/4.jpg';
import blog4gallery1 from '../assets/Blog/Gallery/1.jpg';
import blog4gallery2 from '../assets/Blog/Gallery/2.jpg';
import blog4comment1 from '../assets/Blog/Comments/Pic-1.jpg';
import blog4comment1A from '../assets/Blog/Comments/Pic-2.jpg';

import blog5 from '../assets/Blog/Blogs/5.jpg';
import blog5gallery1 from '../assets/Blog/Gallery/1.jpg';
import blog5gallery2 from '../assets/Blog/Gallery/2.jpg';

import blog6 from '../assets/Blog/Blogs/6.jpg';
import blog6gallery1 from '../assets/Blog/Gallery/1.jpg';
import blog6gallery2 from '../assets/Blog/Gallery/2.jpg';
import blog6comment1 from '../assets/Blog/Comments/Pic-1.jpg';
import blog6comment2 from '../assets/Blog/Comments/Pic-2.jpg';

import blog7 from '../assets/Blog/Blogs/7.jpg';
import blog7gallery1 from '../assets/Blog/Gallery/1.jpg';
import blog7gallery2 from '../assets/Blog/Gallery/2.jpg';
import blog7comment1 from '../assets/Blog/Comments/Pic-1.jpg';

import blog8 from '../assets/Blog/Blogs/8.jpg';
import blog8gallery1 from '../assets/Blog/Gallery/1.jpg';
import blog8gallery2 from '../assets/Blog/Gallery/2.jpg';
import blog8comment1 from '../assets/Blog/Comments/Pic-1.jpg';
import blog8comment2 from '../assets/Blog/Comments/Pic-2.jpg';

import blog9 from '../assets/Blog/Blogs/9.jpg';
import blog9gallery1 from '../assets/Blog/Gallery/1.jpg';
import blog9gallery2 from '../assets/Blog/Gallery/2.jpg';

import blog10 from '../assets/Blog/Blogs/10.jpg';
import blog10gallery1 from '../assets/Blog/Gallery/1.jpg';
import blog10gallery2 from '../assets/Blog/Gallery/2.jpg';
import blog10comment1 from '../assets/Blog/Comments/Pic-1.jpg';
import blog10comment1A from '../assets/Blog/Comments/Pic-2.jpg';
import blog10comment2 from '../assets/Blog/Comments/Pic-3.jpg';

/*Shop*/
import product1Gallery1 from '../assets/Shop/Products/1.jpg';
import product1Gallery2 from '../assets/Shop/Products/2.jpg';
import product1Gallery3 from '../assets/Shop/Products/3.jpg';
import product1Gallery4 from '../assets/Shop/Products/4.jpg';
import product1Review1 from '../assets/Shop/Reviews/Pic-1.jpg';
import product1Review2 from '../assets/Shop/Reviews/Pic-2.jpg';

import product2Gallery1 from '../assets/Shop/Products/2.jpg';
import product2Gallery2 from '../assets/Shop/Products/3.jpg';
import product2Gallery3 from '../assets/Shop/Products/4.jpg';
import product2Gallery4 from '../assets/Shop/Products/5.jpg';
import product2Review1 from '../assets/Shop/Reviews/Pic-1.jpg';

import product3Gallery1 from '../assets/Shop/Products/3.jpg';
import product3Gallery2 from '../assets/Shop/Products/4.jpg';
import product3Gallery3 from '../assets/Shop/Products/5.jpg';
import product3Gallery4 from '../assets/Shop/Products/6.jpg';

import product4Gallery1 from '../assets/Shop/Products/4.jpg';
import product4Gallery2 from '../assets/Shop/Products/5.jpg';
import product4Gallery3 from '../assets/Shop/Products/6.jpg';
import product4Gallery4 from '../assets/Shop/Products/7.jpg';
import product4Review1 from '../assets/Shop/Reviews/Pic-1.jpg';
import product4Review2 from '../assets/Shop/Reviews/Pic-2.jpg';

import product5Gallery1 from '../assets/Shop/Products/5.jpg';
import product5Gallery2 from '../assets/Shop/Products/6.jpg';
import product5Gallery3 from '../assets/Shop/Products/7.jpg';
import product5Gallery4 from '../assets/Shop/Products/8.jpg';

import product6Gallery1 from '../assets/Shop/Products/6.jpg';
import product6Gallery2 from '../assets/Shop/Products/7.jpg';
import product6Gallery3 from '../assets/Shop/Products/8.jpg';
import product6Gallery4 from '../assets/Shop/Products/9.jpg';
import product6Review1 from '../assets/Shop/Reviews/Pic-1.jpg';
import product6Review2 from '../assets/Shop/Reviews/Pic-2.jpg';

import product7Gallery1 from '../assets/Shop/Products/7.jpg';
import product7Gallery2 from '../assets/Shop/Products/8.jpg';
import product7Gallery3 from '../assets/Shop/Products/9.jpg';
import product7Gallery4 from '../assets/Shop/Products/1.jpg';
import product7Review1 from '../assets/Shop/Reviews/Pic-1.jpg';

import product8Gallery1 from '../assets/Shop/Products/8.jpg';
import product8Gallery2 from '../assets/Shop/Products/9.jpg';
import product8Gallery3 from '../assets/Shop/Products/1.jpg';
import product8Gallery4 from '../assets/Shop/Products/2.jpg';

import product9Gallery1 from '../assets/Shop/Products/9.jpg';
import product9Gallery2 from '../assets/Shop/Products/1.jpg';
import product9Gallery3 from '../assets/Shop/Products/2.jpg';
import product9Gallery4 from '../assets/Shop/Products/3.jpg';
import product9Review1 from '../assets/Shop/Reviews/Pic-1.jpg';

import cart1 from '../assets/Shop/Products/1.jpg';
import cart2 from '../assets/Shop/Products/2.jpg';
import cart3 from '../assets/Shop/Products/3.jpg';
import cart4 from '../assets/Shop/Products/4.jpg';
import cart5 from '../assets/Shop/Products/5.jpg';


const Images = {

    /*Global*/
    logo,
    pageTitle,

    /*Home*/
    home1,
    home2,
    home3,

    /*About*/
    about1,
    about2,

    /*Background*/
    counterBg,
    DiscountBg,
    ToursBg,
    TestimonialsBg,

    /*Team*/
    team1,
    team2,
    team3, 
    team4,

    /*Testimonials*/
    testimonial1,
    testimonial2,
    testimonial3,
    testimonial4,

    /*FAQs*/
    FAQs,

    /*Gallery*/
    gallery1,
    gallery2,
    gallery3,
    gallery4,
    gallery5,

    /*Destinations*/
    des1,
    des1gallery1,
    des1gallery2,
    des1gallery3,
    des1gallery4,
    des1gallery5,
    des1gallery6,

    des1tour1,
    des1tour1gallery1,
    des1tour1gallery2,
    des1tour1gallery3,
    des1tour1gallery4,
    des1tour1gallery5,
    des1tour1gallery6,
    des1tour1review1,
    des1tour1review2,

    des1tour2,
    des1tour2gallery1,
    des1tour2gallery2,
    des1tour2gallery3,
    des1tour2gallery4,
    des1tour2gallery5,
    des1tour2gallery6,
    des1tour2review1,
    des1tour2review2,

    des1tour3,
    des1tour3gallery1,
    des1tour3gallery2,
    des1tour3gallery3,
    des1tour3gallery4,
    des1tour3gallery5,
    des1tour3gallery6,
    des1tour3review1,
    des1tour3review2,

    des2,
    des2gallery1,
    des2gallery2,
    des2gallery3,
    des2gallery4,
    des2gallery5,
    des2gallery6,

    des2tour1,
    des2tour1gallery1,
    des2tour1gallery2,
    des2tour1gallery3,
    des2tour1gallery4,
    des2tour1gallery5,
    des2tour1gallery6,
    des2tour1review1,
    des2tour1review2,

    des2tour2,
    des2tour2gallery1,
    des2tour2gallery2,
    des2tour2gallery3,
    des2tour2gallery4,
    des2tour2gallery5,
    des2tour2gallery6,
    des2tour2review1,
    des2tour2review2,

    des2tour3,
    des2tour3gallery1,
    des2tour3gallery2,
    des2tour3gallery3,
    des2tour3gallery4,
    des2tour3gallery5,
    des2tour3gallery6,
    des2tour3review1,
    des2tour3review2,

    des3,
    des3gallery1,
    des3gallery2,
    des3gallery3,
    des3gallery4,
    des3gallery5,
    des3gallery6,

    des3tour1,
    des3tour1gallery1,
    des3tour1gallery2,
    des3tour1gallery3, 
    des3tour1gallery4,
    des3tour1gallery5,
    des3tour1gallery6,
    des3tour1review1,
    des3tour1review2,

    des3tour2,
    des3tour2gallery1,
    des3tour2gallery2,
    des3tour2gallery3,
    des3tour2gallery4,
    des3tour2gallery5,
    des3tour2gallery6,
    des3tour2review1,
    des3tour2review2,

    des3tour3,
    des3tour3gallery1,
    des3tour3gallery2,
    des3tour3gallery3,
    des3tour3gallery4,
    des3tour3gallery5,
    des3tour3gallery6,
    des3tour3review1,
    des3tour3review2,

    des4,
    des4gallery1,
    des4gallery2,
    des4gallery3,
    des4gallery4,
    des4gallery5,
    des4gallery6,

    des4tour1,
    des4tour1gallery1,
    des4tour1gallery2,
    des4tour1gallery3,
    des4tour1gallery4,
    des4tour1gallery5,
    des4tour1gallery6,
    des4tour1review1,
    des4tour1review2,

    des4tour2,
    des4tour2gallery1,
    des4tour2gallery2,
    des4tour2gallery3,
    des4tour2gallery4,
    des4tour2gallery5,
    des4tour2gallery6,
    des4tour2review1,
    des4tour2review2,

    des4tour3,
    des4tour3gallery1,
    des4tour3gallery2,
    des4tour3gallery3,
    des4tour3gallery4,
    des4tour3gallery5,
    des4tour3gallery6,
    des4tour3review1,
    des4tour3review2,

    des5,
    des5gallery1,
    des5gallery2,
    des5gallery3,
    des5gallery4,
    des5gallery5,
    des5gallery6,

    des5tour1,
    des5tour1gallery1,
    des5tour1gallery2,
    des5tour1gallery3,
    des5tour1gallery4,
    des5tour1gallery5,
    des5tour1gallery6,
    des5tour1review1,
    des5tour1review2,

    des5tour2,
    des5tour2gallery1,
    des5tour2gallery2,
    des5tour2gallery3,
    des5tour2gallery4,
    des5tour2gallery5,
    des5tour2gallery6,
    des5tour2review1,
    des5tour2review2,

    des5tour3,
    des5tour3gallery1,
    des5tour3gallery2,
    des5tour3gallery3,
    des5tour3gallery4,
    des5tour3gallery5,
    des5tour3gallery6,
    des5tour3review1,
    des5tour3review2,

    des6,
    des6gallery1,
    des6gallery2,
    des6gallery3,
    des6gallery4,
    des6gallery5,
    des6gallery6,

    des6tour1,
    des6tour1gallery1,
    des6tour1gallery2,
    des6tour1gallery3,
    des6tour1gallery4,
    des6tour1gallery5,
    des6tour1gallery6,
    des6tour1review1,
    des6tour1review2,

    des6tour2,
    des6tour2gallery1,
    des6tour2gallery2,
    des6tour2gallery3,
    des6tour2gallery4,
    des6tour2gallery5,
    des6tour2gallery6,
    des6tour2review1,
    des6tour2review2,

    des6tour3,
    des6tour3gallery1,
    des6tour3gallery2,
    des6tour3gallery3,
    des6tour3gallery4,
    des6tour3gallery5,
    des6tour3gallery6,
    des6tour3review1,
    des6tour3review2,

    des7,
    des7gallery1,
    des7gallery2,
    des7gallery3,
    des7gallery4,
    des7gallery5,
    des7gallery6,

    des7tour1,
    des7tour1gallery1,
    des7tour1gallery2,
    des7tour1gallery3,
    des7tour1gallery4,
    des7tour1gallery5,
    des7tour1gallery6,
    des7tour1review1,
    des7tour1review2,

    des7tour2,
    des7tour2gallery1,
    des7tour2gallery2,
    des7tour2gallery3,
    des7tour2gallery4,
    des7tour2gallery5,
    des7tour2gallery6,
    des7tour2review1,
    des7tour2review2,

    des7tour3,
    des7tour3gallery1,
    des7tour3gallery2,
    des7tour3gallery3,
    des7tour3gallery4,
    des7tour3gallery5,
    des7tour3gallery6,
    des7tour3review1,
    des7tour3review2,

    des8,
    des8gallery1,
    des8gallery2,
    des8gallery3,
    des8gallery4,
    des8gallery5,
    des8gallery6,

    des8tour1,
    des8tour1gallery1,
    des8tour1gallery2,
    des8tour1gallery3,
    des8tour1gallery4,
    des8tour1gallery5,
    des8tour1gallery6,
    des8tour1review1,
    des8tour1review2,

    des8tour2,
    des8tour2gallery1,
    des8tour2gallery2,
    des8tour2gallery3,
    des8tour2gallery4,
    des8tour2gallery5,
    des8tour2gallery6,
    des8tour2review1,
    des8tour2review2,

    des8tour3,
    des8tour3gallery1,
    des8tour3gallery2,
    des8tour3gallery3,
    des8tour3gallery4,
    des8tour3gallery5,
    des8tour3gallery6,
    des8tour3review1,
    des8tour3review2,

    /*Blogs*/
    blog1,
    blog1gallery1,
    blog1gallery2,
    blog1comment1,
    blog1comment1A,
    blog1comment2,

    blog2,
    blog2gallery1,
    blog2gallery2,
    blog2comment1,
    blog2comment2,

    blog3,
    blog3gallery1,
    blog3gallery2,

    blog4,
    blog4gallery1,
    blog4gallery2,
    blog4comment1,
    blog4comment1A,

    blog5,
    blog5gallery1,
    blog5gallery2,

    blog6,
    blog6gallery1,
    blog6gallery2,
    blog6comment1,
    blog6comment2,

    blog7,
    blog7gallery1,
    blog7gallery2,
    blog7comment1,

    blog8,
    blog8gallery1,
    blog8gallery2,
    blog8comment1,
    blog8comment2,

    blog9,
    blog9gallery1,
    blog9gallery2,

    blog10,
    blog10gallery1,
    blog10gallery2,
    blog10comment1,
    blog10comment1A,
    blog10comment2,

    /*Shop*/
    product1Gallery1,
    product1Gallery2,
    product1Gallery3, 
    product1Gallery4,
    product1Review1,
    product1Review2,

    product2Gallery1,
    product2Gallery2,
    product2Gallery3,
    product2Gallery4,
    product2Review1,

    product3Gallery1,
    product3Gallery2,
    product3Gallery3,
    product3Gallery4,

    product4Gallery1,
    product4Gallery2,
    product4Gallery3,
    product4Gallery4,
    product4Review1,
    product4Review2,

    product5Gallery1,
    product5Gallery2,
    product5Gallery3,
    product5Gallery4,

    product6Gallery1,
    product6Gallery2,
    product6Gallery3,
    product6Gallery4,
    product6Review1,
    product6Review2,

    product7Gallery1,
    product7Gallery2,
    product7Gallery3,
    product7Gallery4,
    product7Review1,

    product8Gallery1,
    product8Gallery2,
    product8Gallery3,
    product8Gallery4,

    product9Gallery1,
    product9Gallery2,
    product9Gallery3,
    product9Gallery4,
    product9Review1,

    cart1,
    cart2,
    cart3,
    cart4,
    cart5,

};

export default Images;