import React from 'react';

import { Home, About, Counter, Testimonials, Blogs, Services, Discount, Tours, Destinations, GallerySection, HomeSearch, Team } from '../../sections'; 



const HomePage = () => {   

  return (  
    <div className='homepage'>
      <Home />
      <HomeSearch />
      <About />
      <Services />
      <Counter />
      <GallerySection />
      <Discount /> 
      <Destinations />
      <Tours /> 
      <Team /> 
      <Testimonials />
      <Blogs />
    </div>
  )
}

export default HomePage;