import React from 'react';
import './Product.css';

import { calculateAverageRating } from '../../utils/PopularResult';

import { Link } from 'react-router-dom';
import { FaStar } from 'react-icons/fa';
import { BsCartPlusFill } from 'react-icons/bs';



const Product = ({ image, name, product, content, disprice, price }) => {

  // Calculate the average rating for the product
  const averageRating = calculateAverageRating(product);

  return (
    <div className='product-item'>

      <div className='image'>
        {/* Cart button */}
        <div className='cart'>
          <Link to='/cart'>
            <BsCartPlusFill className='icon' />
            add to cart 
          </Link>
        </div>
        {/* Product image */}
        <img src={image} alt={name} />
      </div>

      <div className='content'>
        {/* Product name */}
        <Link 
          to={{
            pathname: '/Shop/' + name,
          }}
          location={{ product }}
          state={product}
        ><h3>{name}</h3>
        </Link>
        <p>{content}</p> {/* Product description */}
        <div className='price'>
          <p>${disprice} <span>${price}</span></p>{/* Discounted price and original price */}
        </div>
        {/* Star rating */}
        <div className='rating'>
          <div className='icon'>
            <FaStar /> <FaStar /> <FaStar /> <FaStar /> <FaStar />
          </div>
          {/* Display average rating and number of comments */}
          {product.comments.length > 0 ? (
            <h5>
              {averageRating} ({product.comments.length})
            </h5>
          ) : (
            <h5>Not rated</h5>
          )}
        </div>
      </div>

    </div>
  );
};

export default Product;