import React from 'react';
import './MobileNavbar.css';

import { NavbarItem } from '../../components';
import { navbar } from '../../constants';

import { FaAngleDoubleLeft } from 'react-icons/fa';
 


/* Mobile Navbar container */
const MobileNavbar = ({ close }) => {
  return (
    <div className='mobile-menu'>

      {/* Close sidebar button */}
      <div id='close-side-bar' onClick={close}><FaAngleDoubleLeft /></div>

      {/* Navigation items */}  
      <nav className='mobile-navbar'>
        {navbar.map((item) => {
          return (
            <NavbarItem key={item.id} {...item} />
          )
        })}
      </nav>
      
    </div>
  )
}

export default MobileNavbar;