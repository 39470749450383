import React from 'react'
import './Tours.css';

import { Heading, Tour } from '../../../components';
import { desContent } from '../../../constants';
import { getPopular } from '../../../utils/PopularResult';



const Tours = () => { 

  // Step 1: Flatten the tours array
  const tours = desContent.flatMap(dest => dest.tours);

  // Step 2: Get popular tours using the getPopular utility function
  const popularTours = getPopular(tours);

  return (
    <section className='tours linear-bg'>

      {/* Section heading */}
      <Heading title={'popular tours'} span={"Beautiful Places"} />

      <div className='box-container'>
        {
          // Map over the popularTours array to render each tour
          popularTours.map((tour, index) => {
            return (
              <Tour
                key={index}
                image={tour.image}
                price={tour.price}
                name={tour.name}
                duration={tour.duration}
                location={tour.location}
                tour={tour}
              />
            ) 
          })
        }
      </div>

    </section>
  )
}

export default Tours;