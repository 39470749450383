import React from 'react';
import './Discount.css';

import { Button } from '../../../components';



const Discount = () => { 
  return (
    <section className='discount-banner'> 

      <div className='content'>
        {/* Discount title */}
        <h2>discount <span>10-30%</span> off</h2>
        {/* Discount description */}
        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Doloribus eos alias, eius enim officiis error dolorum eveniet, pariatur rem aperiam veritatis.</p>
        {/* Button for booking tours */}
        <Button link={'tours'} title={'book Tours'} />
      </div>

    </section>
  );
}

export default Discount;