import React from 'react';
import './DestinationPage.css';

import { PageTitle, Heading, Destination } from '../../../components';
import { desContent } from '../../../constants';

const DestinationPage = () => {     

  return ( 
    <> 
      {/* Page Title */}
      <PageTitle title={'destinations'} page={'destinations'} /> 

      <section className='destinations'> 
        {/* Heading */}
        <Heading title={'Our destinations'} span={"Our destinations"} /> 

        <div className='box-container'>
          {/* Destination Cards */}
          {desContent.map((des) => {
              return(  
                <Destination 
                  key={des.id} 
                  image={des.image} 
                  name={des.name} 
                  destination={des}  
                  totalTours={des.tours.length}
                />
              )
            })
          }
        </div>

      </section>
    </>
  )
}

export default DestinationPage;