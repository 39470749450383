import React from 'react';
import './SidebarHeading.css';



// Component for the sidebar heading
const SidebarHeading = ({ title }) => {

  return (
    <div className='sidebar-heading'>
      <h2>{title}</h2>
    </div>
  );
  
} 

export default SidebarHeading;