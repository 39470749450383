import { images } from '../constants';

const products = [
    { 
        id: 1,
        image: images.product1Gallery1, 
        name: 'Glasses',
        price: '270.00',  
        disprice: '260.00',
        content: 'Lorem, Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Quam, Quisquam Consectetur! Nulla Dicta Aperiam Necessitatibus, Ad Impedit Cupiditate Illum Possimus Placeat Unde At Deserunt Eum, Quas, Dignissimos Omnis Cum Fugit.',
        description: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Placeat, culpa doloremque suscipit tempore id a tenetur adipisci aliquid pariatur dolores cumque voluptas. Laborum modi sapiente aliquid officia blanditiis cum dolores! Lorem ipsum dolor sit amet consectetur adipisicing elit. Quod distinctio repellendus nobis quaerat architecto ullam molestiae provident dolor saepe consequatur, nihil quis eos commodi, ab eius, laborum consequuntur laudantium! Odit.',
        category: [ 
            {id: 1, title: 'clothes'},
            {id: 2, title: 'accessories'},
        ],
        tags: [ 
            {id: 1, title: 'Adventures'},
            {id: 2, title: 'Luxury'},
            {id: 3, title: 'Beaches'},
        ],
        gallery: {
            image1: images.product1Gallery1,
            image2: images.product1Gallery2,
            image3: images.product1Gallery3,
            image4: images.product1Gallery4,
        },
        addInfo: [
            {id: 1, title: 'glasses', content: 'standard'},
            {id: 2, title: 'shape', content: 'square'},
        ],
        comments: [
            {
                id: 1,
                image: images.product1Review1,
                name: 'Roger Blunt',
                date: 'Mar 21st, 2022',
                rating: 4.8,
                content: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Molestias sapiente ullam esse laudantium corporis pariatur. ',
            },
            {
                id: 2,
                image: images.product1Review2,
                name: 'Trish McKelvey',
                date: 'Aug 5th, 2022',
                rating: 4.9,
                content: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Molestias sapiente ullam esse laudantium corporis pariatur. ',
            },
        ]
    },
    {
        id: 2,
        image: images.product2Gallery1, 
        name: 'Hiking Shoes',
        price: '290.00',
        disprice: '280.00',
        content: 'Lorem, Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Quam, Quisquam Consectetur! Nulla Dicta Aperiam Necessitatibus, Ad Impedit Cupiditate Illum Possimus Placeat Unde At Deserunt Eum, Quas, Dignissimos Omnis Cum Fugit.',
        description: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Placeat, culpa doloremque suscipit tempore id a tenetur adipisci aliquid pariatur dolores cumque voluptas. Laborum modi sapiente aliquid officia blanditiis cum dolores! Lorem ipsum dolor sit amet consectetur adipisicing elit. Quod distinctio repellendus nobis quaerat architecto ullam molestiae provident dolor saepe consequatur, nihil quis eos commodi, ab eius, laborum consequuntur laudantium! Odit.',
        category: [ 
            {id: 1, title: 'clothes'},
            {id: 2, title: 'camping'},
        ],
        tags: [ 
            {id: 1, title: 'Hiking'},
            {id: 2, title: 'Adventures'},
            {id: 3, title: 'Sightseeing'},
        ],
        gallery: {
            image1: images.product2Gallery1,
            image2: images.product2Gallery2,
            image3: images.product2Gallery3,
            image4: images.product2Gallery4,
        },
        addInfo: [
            {id: 1, title: 'glasses', content: 'standard'},
            {id: 2, title: 'shape', content: 'square'},
        ],
        comments: [
            {
                id: 1,
                image: images.product2Review1,
                name: 'Roger Blunt',
                date: 'Mar 21st, 2022',
                rating: 4.5,
                content: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Molestias sapiente ullam esse laudantium corporis pariatur. ',
            },
        ]
    },
    {
        id: 3,
        image: images.product3Gallery1, 
        name: 'Camping tent',
        price: '310.00',
        disprice: '300.00',
        content: 'Lorem, Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Quam, Quisquam Consectetur! Nulla Dicta Aperiam Necessitatibus, Ad Impedit Cupiditate Illum Possimus Placeat Unde At Deserunt Eum, Quas, Dignissimos Omnis Cum Fugit.',
        description: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Placeat, culpa doloremque suscipit tempore id a tenetur adipisci aliquid pariatur dolores cumque voluptas. Laborum modi sapiente aliquid officia blanditiis cum dolores! Lorem ipsum dolor sit amet consectetur adipisicing elit. Quod distinctio repellendus nobis quaerat architecto ullam molestiae provident dolor saepe consequatur, nihil quis eos commodi, ab eius, laborum consequuntur laudantium! Odit.',
        category: [ 
            {id: 1, title: 'camping'},
            {id: 2, title: 'equipments'},
        ],
        tags: [ 
            {id: 1, title: 'Hiking'},
            {id: 2, title: 'Relaxation'},
            {id: 3, title: 'Sightseeing'},
        ],
        gallery: {
            image1: images.product3Gallery1,
            image2: images.product3Gallery2,
            image3: images.product3Gallery3,
            image4: images.product3Gallery4,
        },
        addInfo: [
            {id: 1, title: 'glasses', content: 'standard'},
            {id: 2, title: 'shape', content: 'square'},
        ],
        comments: []
    },
    {
        id: 4,
        image: images.product4Gallery1, 
        name: 'Torch',
        price: '330.00',
        disprice: '320.00',
        content: 'Lorem, Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Quam, Quisquam Consectetur! Nulla Dicta Aperiam Necessitatibus, Ad Impedit Cupiditate Illum Possimus Placeat Unde At Deserunt Eum, Quas, Dignissimos Omnis Cum Fugit.',
        description: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Placeat, culpa doloremque suscipit tempore id a tenetur adipisci aliquid pariatur dolores cumque voluptas. Laborum modi sapiente aliquid officia blanditiis cum dolores! Lorem ipsum dolor sit amet consectetur adipisicing elit. Quod distinctio repellendus nobis quaerat architecto ullam molestiae provident dolor saepe consequatur, nihil quis eos commodi, ab eius, laborum consequuntur laudantium! Odit.',
        category: [ 
            {id: 1, title: 'accessories'},
            {id: 2, title: 'equipments'},
        ],
        tags: [ 
            {id: 1, title: 'Sightseeing'},
            {id: 2, title: 'Packages'},
            {id: 3, title: 'Activities'},
        ],
        gallery: {
            image1: images.product4Gallery1,
            image2: images.product4Gallery2,
            image3: images.product4Gallery3,
            image4: images.product4Gallery4,
        },
        addInfo: [
            {id: 1, title: 'glasses', content: 'standard'},
            {id: 2, title: 'shape', content: 'square'},
        ],
        comments: [
            {
                id: 1,
                image: images.product4Review1,
                name: 'Roger Blunt',
                date: 'Mar 21st, 2022',
                rating: 5,
                content: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Molestias sapiente ullam esse laudantium corporis pariatur. ',
            },
            {
                id: 2,
                image: images.product4Review2,
                name: 'Trish McKelvey',
                date: 'Aug 5th, 2022',
                rating: 4.8,
                content: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Molestias sapiente ullam esse laudantium corporis pariatur. ',
            },
        ]
    },
    {
        id: 5,
        image: images.product5Gallery1, 
        name: 'Skateboard',
        price: '350.00',
        disprice: '340.00',
        content: 'Lorem, Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Quam, Quisquam Consectetur! Nulla Dicta Aperiam Necessitatibus, Ad Impedit Cupiditate Illum Possimus Placeat Unde At Deserunt Eum, Quas, Dignissimos Omnis Cum Fugit.',
        description: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Placeat, culpa doloremque suscipit tempore id a tenetur adipisci aliquid pariatur dolores cumque voluptas. Laborum modi sapiente aliquid officia blanditiis cum dolores! Lorem ipsum dolor sit amet consectetur adipisicing elit. Quod distinctio repellendus nobis quaerat architecto ullam molestiae provident dolor saepe consequatur, nihil quis eos commodi, ab eius, laborum consequuntur laudantium! Odit.',
        category: [ 
            {id: 1, title: 'accessories'},
            {id: 2, title: 'equipments'},
        ], 
        tags: [ 
            {id: 1, title: 'Landmarks'},
            {id: 2, title: 'Destinations'},
            {id: 3, title: 'Shopping'},
        ],
        gallery: {
            image1: images.product5Gallery1,
            image2: images.product5Gallery2,
            image3: images.product5Gallery3,
            image4: images.product5Gallery4,
        },
        addInfo: [
            {id: 1, title: 'glasses', content: 'standard'},
            {id: 2, title: 'shape', content: 'square'},
        ],
        comments: []
    },
    {
        id: 6,
        image: images.product6Gallery1, 
        name: 'Bagpack',
        price: '370.00',
        disprice: '360.00',
        content: 'Lorem, Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Quam, Quisquam Consectetur! Nulla Dicta Aperiam Necessitatibus, Ad Impedit Cupiditate Illum Possimus Placeat Unde At Deserunt Eum, Quas, Dignissimos Omnis Cum Fugit.',
        description: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Placeat, culpa doloremque suscipit tempore id a tenetur adipisci aliquid pariatur dolores cumque voluptas. Laborum modi sapiente aliquid officia blanditiis cum dolores! Lorem ipsum dolor sit amet consectetur adipisicing elit. Quod distinctio repellendus nobis quaerat architecto ullam molestiae provident dolor saepe consequatur, nihil quis eos commodi, ab eius, laborum consequuntur laudantium! Odit.',
        category: [ 
            {id: 1, title: 'accessories'},
            {id: 2, title: 'camping'},
        ], 
        tags: [ 
            {id: 1, title: 'Adventures'},
            {id: 2, title: 'Hiking'},
            {id: 3, title: 'Wildlife'},
        ],
        gallery: {
            image1: images.product6Gallery1,
            image2: images.product6Gallery2,
            image3: images.product6Gallery3,
            image4: images.product6Gallery4,
        },
        addInfo: [
            {id: 1, title: 'glasses', content: 'standard'},
            {id: 2, title: 'shape', content: 'square'},
        ],
        comments: [
            {
                id: 1,
                image: images.product6Review1,
                name: 'Roger Blunt',
                date: 'Mar 21st, 2022',
                rating: 4.5,
                content: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Molestias sapiente ullam esse laudantium corporis pariatur. ',
            },
            {
                id: 2,
                image: images.product6Review2,
                name: 'Trish McKelvey',
                date: 'Aug 5th, 2022',
                rating: 4.5,
                content: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Molestias sapiente ullam esse laudantium corporis pariatur. ',
            },
        ]
    }, 
    {
        id: 7,
        image: images.product7Gallery1, 
        name: 'camera',
        price: '390.00',
        disprice: '380.00',
        content: 'Lorem, Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Quam, Quisquam Consectetur! Nulla Dicta Aperiam Necessitatibus, Ad Impedit Cupiditate Illum Possimus Placeat Unde At Deserunt Eum, Quas, Dignissimos Omnis Cum Fugit.',
        description: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Placeat, culpa doloremque suscipit tempore id a tenetur adipisci aliquid pariatur dolores cumque voluptas. Laborum modi sapiente aliquid officia blanditiis cum dolores! Lorem ipsum dolor sit amet consectetur adipisicing elit. Quod distinctio repellendus nobis quaerat architecto ullam molestiae provident dolor saepe consequatur, nihil quis eos commodi, ab eius, laborum consequuntur laudantium! Odit.',
        category: [ 
            {id: 1, title: 'equipments'},
        ], 
        tags: [ 
            {id: 1, title: 'Sightseeing'},
            {id: 2, title: 'Destinations'},
            {id: 3, title: 'Luxury'},
        ],
        gallery: {
            image1: images.product7Gallery1,
            image2: images.product7Gallery2,
            image3: images.product7Gallery3,
            image4: images.product7Gallery4,
        },
        addInfo: [
            {id: 1, title: 'glasses', content: 'standard'},
            {id: 2, title: 'shape', content: 'square'},
        ],
        comments: [
            {
                id: 1,
                image: images.product7Review1,
                name: 'Roger Blunt',
                date: 'Mar 21st, 2022',
                rating: 4,
                content: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Molestias sapiente ullam esse laudantium corporis pariatur. ',
            },
        ]
    },
    {
        id: 8,
        image: images.product8Gallery1, 
        name: 'binoculars',
        price: '410.00',
        disprice: '400.00',
        content: 'Lorem, Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Quam, Quisquam Consectetur! Nulla Dicta Aperiam Necessitatibus, Ad Impedit Cupiditate Illum Possimus Placeat Unde At Deserunt Eum, Quas, Dignissimos Omnis Cum Fugit.',
        description: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Placeat, culpa doloremque suscipit tempore id a tenetur adipisci aliquid pariatur dolores cumque voluptas. Laborum modi sapiente aliquid officia blanditiis cum dolores! Lorem ipsum dolor sit amet consectetur adipisicing elit. Quod distinctio repellendus nobis quaerat architecto ullam molestiae provident dolor saepe consequatur, nihil quis eos commodi, ab eius, laborum consequuntur laudantium! Odit.',
        category: [ 
            {id: 1, title: 'accessories'},
            {id: 2, title: 'equipments'},
        ], 
        tags: [ 
            {id: 1, title: 'Hiking'},
            {id: 2, title: 'Sightseeing'},
            {id: 3, title: 'Destinations'},
        ],
        gallery: {
            image1: images.product8Gallery1,
            image2: images.product8Gallery2,
            image3: images.product8Gallery3,
            image4: images.product8Gallery4,
        }, 
        addInfo: [
            {id: 1, title: 'glasses', content: 'standard'},
            {id: 2, title: 'shape', content: 'square'},
        ],
        comments: []
    },
    {
        id: 9,
        image: images.product9Gallery1, 
        name: 'Hat',
        price: '420.00',
        disprice: '410.00',
        content: 'Lorem, Ipsum Dolor Sit Amet Consectetur Adipisicing Elit. Quam, Quisquam Consectetur! Nulla Dicta Aperiam Necessitatibus, Ad Impedit Cupiditate Illum Possimus Placeat Unde At Deserunt Eum, Quas, Dignissimos Omnis Cum Fugit.',
        description: 'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Placeat, culpa doloremque suscipit tempore id a tenetur adipisci aliquid pariatur dolores cumque voluptas. Laborum modi sapiente aliquid officia blanditiis cum dolores! Lorem ipsum dolor sit amet consectetur adipisicing elit. Quod distinctio repellendus nobis quaerat architecto ullam molestiae provident dolor saepe consequatur, nihil quis eos commodi, ab eius, laborum consequuntur laudantium! Odit.',
        category: [ 
            {id: 1, title: 'clothes'},
            {id: 2, title: 'accessories'},
        ],
        tags: [ 
            {id: 1, title: 'Hiking'},
            {id: 2, title: 'Beaches'},
            {id: 3, title: 'Adventures'},
        ],
        gallery: {
            image1: images.product9Gallery1,
            image2: images.product9Gallery2,
            image3: images.product9Gallery3,
            image4: images.product9Gallery4,
        },
        addInfo: [
            {id: 1, title: 'glasses', content: 'standard'},
            {id: 2, title: 'shape', content: 'square'},
        ],
        comments: [
            {
                id: 1,
                image: images.product9Review1,
                name: 'Roger Blunt',
                date: 'Mar 21st, 2022',
                rating: 4.4,
                content: 'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Molestias sapiente ullam esse laudantium corporis pariatur. ',
            },
        ]
    },
 
];

export default products;