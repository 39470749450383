import React from 'react';

import { SearchTours } from '../../../components';

import { useNavigate } from 'react-router-dom';



const HomeSearch = () => {

  const navigate = useNavigate(); 

  const handleSearchSubmit = (destination, duration, maxBudget) => {

    // Create a new URLSearchParams object to build the search query string
    const searchParams = new URLSearchParams();
    searchParams.set('destination', destination);
    searchParams.set('duration', duration);
    searchParams.set('maxBudget', maxBudget);
    
    // Navigate to the Tours page with the search query parameters
    navigate(`/Tours?${searchParams.toString()}`);

  };

  return <SearchTours onFormSubmit={handleSearchSubmit} />;
  
}

export default HomeSearch;