import React from 'react';
import './Team.css';

import { Heading, TeamItem } from '../../../components';
import { teamContent } from '../../../constants';



const Team = () => {

  return ( 
    <section className='team'>

      {/* Section heading */}
      <Heading title={'our team'} span={'meet our team'} />

      <div className='box-container'>
        {
          // Map over the teamContent array to render each team member
          teamContent.map((team) => {
            return (
              <TeamItem
                key={team.id}
                image={team.image}
                name={team.name}
                title={team.title}
                accounts={team.accounts}
              />
            )
          })
        }
      </div>

    </section>
  )
  
}

export default Team;