import React from 'react';
import './Logo.css';

import { Link } from "react-router-dom";
import { images } from '../../constants';



const Logo = () => {

  return (
    <Link to='/' className='logo'>

      {/* Render the logo image */} 
      <img src={images.logo} alt='logo' /> 

      {/* Render the logo text */}
      <h3>Altours</h3>
      
    </Link>
  );

}

export default Logo;