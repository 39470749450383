import React from 'react';
import './Faqs.css';

import { PageTitle, FaqItem } from '../../../components';
import { faqsContent, images } from '../../../constants';



const Faqs = () => {
  
  return (
    <> 
      {/* Page title */}
      <PageTitle title={'faqs'} page={'faqs'} />

      <section className='faq'>

        {/* Faq Image */}
        <div className='image'>
          <img src={images.FAQs} alt='Faq-Image' />
        </div>

        {/* Faq Accordion container */}
        <div className='accordion-container'>
          {/* Faq items */}
          {faqsContent.map((faq) => {
            return <FaqItem key={faq.id} title={faq.heading} content={faq.content} />;
          })}
        </div>
        
      </section>
    </>
  );
};

export default Faqs;