import { images } from "../constants";

export const cartItems = [
    {
        id: 1,  
        image: images.cart1,
        name: 'Glasses',
        price: '50.00',
        quantity: '1',
        total: '50.00',
    },
    {
        id: 2,
        image: images.cart2,
        name: 'Hiking Shoes',
        price: '10.00', 
        quantity: '1',
        total: '10.00',
    },
    {
        id: 3,
        image: images.cart3,
        name: 'Camping tent',
        price: '20.00',
        quantity: '2',
        total: '40.00',
    },
    {
        id: 4,
        image: images.cart4,
        name: 'Torch',
        price: '50.00',
        quantity: '2',
        total: '100.00',
    },
    {
        id: 5,
        image: images.cart5,
        name: 'Skateboard',
        price: '50.00',
        quantity: '1',
        total: '50.00',
    },
];


export const cartSummary = [
    {id: 1, name: 'sub total', value: '250.00'},
    {id: 2, name: 'delivery fee', value: '50.00'},
    {id: 3, name: 'discount', value: '20.00'},
    {id: 4, name: 'tax', value: '20.00'},
    {id: 5, name: 'total', value: '300.00'},
];