import React from 'react';
import './Services.css';

import { Heading } from '../../../components';
import { serviceContent } from '../../../constants';



const Services = () => { 

  return (
    <section className='services'>

      {/* Section heading */}
      <Heading title={'our services'} span={'what we offer'} />

      <div className='box-container'>
        {
          // Map over the serviceContent array to render each service item
          serviceContent.map((service) => {
            return (
              <div className='service-item' key={service.id}>
                
                {/* Service Icon */}
                <div className='icon'>{service.icon}</div>

                {/* Service Content */}
                <div className='content'>
                  <h3>{service.title}</h3> {/* Service Title */}
                  <p>{service.content}</p> {/* Service Content */}
                </div>

              </div>
            )
          })
        }
      </div>
    </section>
  )
}

export default Services;