import React from 'react';
import './ShopSummary.css';



const ShopSummary = ({ summary }) => {
  return (
    <div className='shop-summary'>
      {/* Iterate over each item in the summary array */}
      {summary.map((item) => {
        return (
          <div className='summary-item' key={item.id}>
            <div className='box name'>{item.name}</div> {/* Name of the item */} 
            <div className='box value'>${item.value}</div> {/* Value of the item */}
          </div>
        );
      })}
    </div>
  );
}

export default ShopSummary;
