import React from 'react';
import './Blog.css';

import { Link } from 'react-router-dom';
 

 
const Blog = (props) => {

  const { id, image, date, heading, content, blog, admin, category } = props;

  return (
    <div className='blog-item' key={id}>

      {/* Blog image */}
      <div className='image'>
        <img src={image} alt='Blog' />
      </div>

      <div className='content'>
        {/* Blog category */}
        <Link
          className='label'
          key={blog.id}
          to={`/Blogs/category/${category}`}>
          {category}
        </Link>
        
        {/* Link to the individual blog post */}
        <Link
          to={{
            pathname: '/Blogs/' + heading,
          }}
          state={blog}
          className='main-heading' >
          {/* Blog heading */}
          {heading}
        </Link>

        {/* Blog content */}
        <p>{content}</p>

        {/* blog Details section */}
        <div className='details'>
          <span>By {admin}</span> {/* blog admin */}
          <span className='seperator'>|</span>
          <span>{date}</span> {/* blog date */}
        </div>
        
      </div>
    </div>
  );
};

export default Blog;