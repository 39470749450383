import React from 'react';
import './Footer.css';

import { FaFacebookF, FaTwitter, FaInstagram, FaLinkedin } from 'react-icons/fa';
import { FaCaretSquareRight } from 'react-icons/fa';
import { AiTwotonePhone } from 'react-icons/ai'; 
import { FaEnvelope } from 'react-icons/fa';
import { BsMapFill } from 'react-icons/bs';

import { Link } from 'react-router-dom';



const Footer = () => {  
  
  return (
    <footer className='footer'>
      
      <div className='box-container'>

        {/* About Section */}
        <div className='footer-item'> 
          <Link to='/' className='logo'><h2>About Altours</h2></Link> 
          <p>We believe everyone should travel Because travelling’s for everyone. We make vacations magical Making life the daring adventure you deserve.</p>
        </div> 

        {/* Useful Links Section */}
        <div className='footer-item'> 
          <h2>useful links</h2>
          <div className='info links pages'> 
            <div className='links-item'>
              <p><FaCaretSquareRight className='icon' /><Link to='/'>home</Link></p> 
              <p><FaCaretSquareRight className='icon' /><Link to='/about-us'>about</Link></p>
              <p><FaCaretSquareRight className='icon' /><Link to='/Testimonials'>testimonials</Link></p> 
              <p><FaCaretSquareRight className='icon' /><Link to='/Gallery'>gallery</Link></p>
              <p><FaCaretSquareRight className='icon' /><Link to='/Destinations'>destinations</Link></p>
            </div>
            <div className='links-item'> 
              <p><FaCaretSquareRight className='icon' /><Link to='/Tours'>tours</Link></p>
              <p><FaCaretSquareRight className='icon' /><Link to='/Blog-grid'>blogs</Link></p>
              <p><FaCaretSquareRight className='icon' /><Link to='/Shop-grid'>shop</Link></p>
              <p><FaCaretSquareRight className='icon' /><Link to='/Contact-us'>contact</Link></p>
            </div>
          </div>
        </div> 

        {/* Contact Info Section */}
        <div className='footer-item'>  
          <h2>contact info</h2> 
          <div className='info connect'>
            <p><AiTwotonePhone className='icon' /><span>+111-222-333</span></p>
            <p><FaEnvelope className='icon' /><span className='gmail'>abc@gmail.com</span></p>
            <p><BsMapFill className='icon' /><span>karachi, pakistan</span></p>
          </div>
          <div className='social'>
            <a href="/#"><FaFacebookF className='icon' /></a>
            <a href="/#"><FaTwitter className='icon' /></a>
            <a href="/#"><FaInstagram className='icon' /></a>
            <a href="/#"><FaLinkedin className='icon' /></a>
          </div> 
        </div>

      </div>
 
      <div className='content'>
        <p>designed by <span>AS_Designs</span> | all rights reserved</p>
      </div>
      
    </footer>
  )
}

export default Footer;