import React from 'react';

import { PageTitle } from '../../../components';
import { Team } from '../../../sections';



const TeamPage = () => {
  return ( 
    <>
      {/* Page title */}
      <PageTitle title={'Team'} page={'Team'} />

      {/* Team section */}
      <Team />
    </>
  );
};

export default TeamPage;