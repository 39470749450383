import React from 'react';
import './About.css';

import { Button, Heading } from '../../../components';
import { images } from '../../../constants'; 



const About = () => {

  return (
    <section className='about'>

      {/* Section heading */}
      <Heading title={'about us'} span={'know about us'} />

      <div className='box-container'>

        {/* About content */}
        <div className='content'>
          {/* About description */}
          <h3>We make the plan, You pack your bags.</h3>
          <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequuntur, ea cum.</p>
          <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Consequuntur, ea cum. Vero deserunt quia aut magnam enim molestias, ex culpa libero quod consequatur, nobis natus sunt quos animi reprehenderit modi?</p>
          {/* Contact button */}
          <Button link={'contact-us'} title={'contact us'} />
        </div>

        {/* About images */}
        <div className='image'>
          <img src={images.about1} alt='About-Pic' />
          <img src={images.about2} alt='About-Pic' />
        </div>
      </div>
    </section>
  );
}

export default About;