import React from 'react';

import { PageTitle } from '../../../components';

import { Link } from 'react-router-dom';
import { FaEnvelope } from 'react-icons/fa';



const ForgotPwd = () => {

  const handleSubmit = (event) => {
    event.preventDefault(); // Prevent the default form submission behavior
    // Reset the form fields
    event.target.reset();
  };

  return (
    <> 
      {/* Page Title */}
      <PageTitle title={'Reset Password'} page={'Reset Password'} />

      <section className='reset-pwd' onSubmit={handleSubmit}>
        {/* Forgot Password Form */}
        <form className='account-form'>
          <h3>forgot password!</h3>
          {/* Email Input */} 
          <div className='input-field'>
            <label htmlFor='email'><FaEnvelope className='icon' /></label>
            <input type="email" name="email" placeholder="enter your email" id="email" className='box' required />
          </div>
          {/* Submit Button */}
          <button type="submit" name="submit" id="submit" className='btn'>send reset link</button>
          {/* Register Link */}
          <p>don't have an account? <Link to='/register' className='link'>register</Link></p>
        </form>
      </section>
    </>
  )
}

export default ForgotPwd;