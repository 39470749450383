import React from 'react';

import { Heading, Destination } from '../../../components';
import { desContent } from '../../../constants';

import { calculateAverageRating } from '../../../utils/PopularResult';



const Destinations = () => { 

  // Calculate average ratings for each destination
  const destinationAverages = desContent.map(destination => {
    const toursWithComments = destination.tours.filter(tour => tour.comments.length > 0);
    const totalRating = toursWithComments.reduce((sum, tour) => sum + calculateAverageRating(tour), 0);
    const averageRating = totalRating / toursWithComments.length || 0; // Handle division by zero
    return { 
      ...destination,
      averageTourRating: averageRating.toFixed(1)
    };
  });

  // Sort destinations by average rating in descending order
  const popularDestinations = destinationAverages.sort((a, b) => b.averageTourRating - a.averageTourRating);

  return (
    <section className='destinations'>
      
      {/* Section heading */}
      <Heading title={'popular destintions'} span={"Top Tourist Destinations"} />

      <div className='box-container'>
        {/* Render each destination */}
        {popularDestinations.map((des) => {
          return (
            <Destination
              key={des.id}
              image={des.image}
              name={des.name}
              destination={des}
              totalTours={des.tours.length}
            />
          );
        })}
      </div>
    </section>
  );
}

export default Destinations;