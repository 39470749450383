import React from 'react';
import './TourSingle.css';

import { Button, Comments, Lightbox, PageTitle } from '../../../components';
import { calculateAverageRating } from '../../../utils/PopularResult';

import { FaStar, FaCircleNotch, FaCalendarCheck, FaUserAlt, FaMoneyBillWave, FaTicketAlt, FaEnvelope, FaPhoneAlt, FaComment } from 'react-icons/fa';
import { MdLocationOn } from 'react-icons/md';
import { BsMap, BsClock, BsCalendar } from 'react-icons/bs';
import { AiOutlineCheckCircle, AiOutlineCloseCircle } from 'react-icons/ai';
import { useLocation } from "react-router-dom"; 

  
  
const TourSingle = () => {  

  const location = useLocation();
  const tour = location.state;  // Get the tour object from location state

  const plan = tour.tourPlan; // Extract tour plan from tour object
  const tourReviews = tour.comments; // Extract tour comments from tour object
   
  // Calculate average rating using tour object
  const averageRating = calculateAverageRating(tour);

  const handleSubmit = (event) => {
    event.preventDefault(); // Prevent the default form submission behavior
    // Reset the form fields
    event.target.reset();
  };

  const handleTour = (event) => {
    event.preventDefault(); // Prevent the default form submission behavior
    // Reset the form fields
    event.target.reset();
  };

  return (   
    <>
      {/* Page title */}
      <PageTitle title={'tour single'} page={'tour single'} />
      <section className='tour-single'>
  
        {/* Tour information */}
        <div className='tour-info' key={tour.id}>
  
          {/* Tour image */}
          <div className='tour-image'>
            <img src={tour.image} alt='Tour' />
            <div className='price'>${tour.price}</div>
          </div>
  
          {/* Tour content */}
          <div className='content'>
        
            {/* Tour name */}
            <h3 className='main-heading'>{tour.name}</h3>
        
            {/* Tour details */}
            <div className='details'>
              <h3><BsCalendar className='icon' /><span>{tour.duration}</span></h3>
              <h3><BsMap className='icon' /><span>{tour.location}</span></h3>
        
              {/* Tour rating */}
              <div className='rating'>
                <div className='icon'>
                  <FaStar /> <FaStar /> <FaStar /> <FaStar /> <FaStar />
                </div>
                {/* Show average rating and comment count if available */}
                {tour.comments.length > 0 ? (
                  <h5>{averageRating} ({tour.comments.length})</h5>
                ) : (
                  <h5>Not rated</h5>
                )}
              </div>
            </div>
        
            {/* Tour content */}
            <p>{tour.content}</p>
        
          </div>

          <div className='tour-details'>

            {/* Sub-heading */}
            <h3 className='sub-heading'>tour details</h3>
            
            {/* Description */}
            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto, vel facere iste nobis exercitationem dicta? Sapiente repellendus quis in sequi, repellat, maiores architecto debitis reiciendis odio impedit omnis distinctio ullam.</p>
            
            {/* Tour details list */}
            <ul>
              <li>
                <h5><BsMap className='icon' />location</h5>
                <p>{tour.location}</p>
              </li>
              <li>
                <h5><FaCalendarCheck className='icon' />check in</h5>
                <p>{tour.checkIn}</p>
              </li>
              <li>
                <h5><FaCalendarCheck className='icon' />check out</h5>
                <p>{tour.checkOut}</p>
              </li>
              <li>
                <h5><BsClock className='icon' />duration</h5>
                <p>{tour.duration}</p>
              </li>
              <li>
                <h5><FaUserAlt className='icon' />person</h5>
                <p>{tour.person}</p>
              </li>
              <li>
                <h5><FaMoneyBillWave className='icon' />price</h5>
                <p>{tour.price}</p>
              </li>
              <li>
                <h5><AiOutlineCheckCircle className='icon' />included</h5>
                <div className='list'>
                  {/* Render included items */}
                  {(tour.included).map((item) => {
                    return <span key={item.id}>{item.title}</span>
                  })}
                </div>
              </li>
              <li>
                <h5><AiOutlineCloseCircle className='icon' />excluded</h5>
                <div className='list'>
                  {/* Render excluded items */}
                  {(tour.excluded).map((item) => {
                    return <span key={item.id}>{item.title}</span>
                  })}
                </div>
              </li>
            </ul>
          </div>

          {/* Tour Plan */}
          <div className='tour-plan'>
            {/* Sub-heading */}
            <h3 className='sub-heading'>tour plan</h3>
            
            <div className='box-container'>
              {
                (plan).map((item) => {
                  return (
                    <div className='plan-item' key={item.id}>
                      {/* Location dot */}
                      <MdLocationOn className='dot' />
                      
                      <div className='content'>
                        {/* Plan item title */}
                        <h3>{item.title}</h3>
                        
                        {/* Plan item content */}
                        <p>{item.content}</p>
                        
                        <ul>
                          {/* Render plan item points */}
                          {
                            (item.points).map((point) => {
                              return (
                                <li key={point.id}>
                                  <FaCircleNotch className='icon' /> {/* Point icon */}
                                  <span>{point.title}</span> {/* Point title */}
                                </li>
                              )
                            })
                          }
                        </ul>
                      </div>
                    </div>
                  ) 
                })
              }
            </div>
          </div>

          {/* Tour Map */}
          <div className='tour-map'>   
            {/* Sub-heading */}
            <h3 className='sub-heading'>tour map</h3> 

            {/* Google Map iframe */}
            <iframe id='mapIframe' src={tour.map} title="Google Map" allowFullScreen loading="lazy"></iframe>
          </div>

          <div className='tour-gallery'>
            {/* Sub-heading */}
            <h3 className='sub-heading'>tour gallery</h3> 

            {/* Lightbox gallery */}
            <Lightbox images={tour.gallery} className={'tour'} />
          </div>

          {/* Render comments if there are any */}
          {tour.comments.length > 0 && (
            <Comments comments={tourReviews} />
          )}
          
          <div className='leave-review' onSubmit={handleSubmit}> 
            {/* Review form */}
            <form className='form'>
              {/* Form heading */}
              <h3>leave a review</h3>  

              {/* Input boxes */}
              <div className='input-box'>
                <input type="text" name="name" className='box' id="name" placeholder="name" required />
                <input type="email" name="email" className='box' id="email" placeholder="email" required />
              </div>

              {/* Comment textarea */}
              <textarea cols="30" rows="10" name="comment" className='box' id="comment" placeholder="comment"></textarea>

              {/* Submit button */}
              <button type="submit" name="submit" id="submit" className='btn'>submit</button>
            </form>
          </div>

        </div>

        <div className='sidebar'>

          {/* Booking form */}
          <form className='booking-tour' onSubmit={handleTour}>

            {/* Form heading */}
            <h3>book this tour</h3>

            {/* Input fields */}
            <div className='input-field'>
              <label htmlFor='booking-name'><FaUserAlt className='icon' /></label> 
              <input type="text" name="booking-name" placeholder="name" id="booking-name" className='box' /> 
            </div>

            <div className='input-field'>
              <label htmlFor='booking-email'><FaEnvelope className='icon' /></label>
              <input type="email" name="booking-email" placeholder="email" id="booking-email" className='box' /> 
            </div>

            <div className='input-field'>
              <label htmlFor='confirm-email'><FaEnvelope className='icon' /></label>
              <input type="email" name="confirm-email" placeholder="confirm email" id="confirm-email" className='box' />
            </div>

            <div className='input-field'>
              <label htmlFor='phone-no'><FaPhoneAlt className='icon' /></label> 
              <input type="number" name="phone" placeholder="phone number" id="phone-no" className='box' /> 
            </div>

            <div className='input-field'>
              <label htmlFor='date'><BsCalendar className='icon' /></label> 
              <input type="date" name="date" id="date" className='box' />
            </div>

            <div className='input-field'>
              <label htmlFor='tickets'><FaTicketAlt className='icon' /></label>
              <input type="number" name="tickets" placeholder="number of tickets" id="tickets" className='box' /> 
            </div>

            <div className='input-field'>
              <label htmlFor='message' className='fa-comment'><FaComment className='icon' /></label> 
              <textarea cols="30" rows="10" name="message" className='box' id="message" placeholder="message"></textarea>
            </div>
        
            {/* Submit button */}
            <button type="submit" name="login" id="login-btn" className='btn'>book tour</button>

          </form>  
        </div>

      </section>

    </>

  )
}

export default TourSingle;