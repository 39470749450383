import { images } from '.';

const speakers = [ 
    {
        id: 1,
        image: images.team1,
        name: 'Bill Merritt',
        title: 'Founder & CEO',
        accounts: {
            facebook: '#',
            twitter: '#',
            instagram: '#',
            linkedin: '#',
        },
    }, 
    {
        id: 2,
        image: images.team2,
        name: 'Karen Marsh',
        title: 'Manager',
        accounts: {
            facebook: '#',
            twitter: '#',
            instagram: '#',
            linkedin: '#',
        },
    },  
    {
        id: 3,
        image: images.team3,
        name: 'Jack Newman',
        title: 'tour guide',
        accounts: {
            facebook: '#',
            twitter: '#',
            instagram: '#',
            linkedin: '#',
        },
    }, 
    {
        id: 4,
        image: images.team4,
        name: 'Sue Brown',
        title: 'tour guide',
        accounts: {
            facebook: '#',
            twitter: '#',
            instagram: '#',
            linkedin: '#',
        },
    }, 

];

export default speakers;