import React, { useState, useEffect } from 'react';
import './Header.css';

import { HeaderItem, MobileNavbar, Logo } from '../../components';
import { navbar } from '../../constants';

import { FaBars } from 'react-icons/fa'; 
import { useLocation } from 'react-router-dom';



const Header = () => { 

  const [click, setClick] = useState(false);
  const [isOpen, setOpen] = useState(false);

  const { pathname } = useLocation();

  // Handle click on menu button
  const handleClick = () => {
    setClick(true);
    setOpen(true);
  };

  // Close mobile navbar
  const closeMenuBar = () => {
    setClick(false);
    setOpen(false);
  };

  // Close the Navbar between navigation
  useEffect(() => {
    setOpen(false);
  }, [pathname]);

  // Manage On Scroll Functionality
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  });

  useEffect(() => {
    window.addEventListener('scroll', stickyHeader);
    return () => {
      window.removeEventListener('scroll', stickyHeader);
    };
  });

  // Add active class to header on scroll
  const stickyHeader = (e) => {
    const header = document.querySelector('.header');
    const scrollTop = window.scrollY;
    scrollTop >= 60 ? header.classList.add('active') : header.classList.remove('active');
  };

  // Handle scroll event
  const handleScroll = (e) => {
    /* Close Menubar On Scroll */
    const navbar = document.querySelector('.navbar');
    navbar.classList.remove('active');
    setClick(false);
    setOpen(false);
  };

  return (
    <nav className='header'>

      {/* Logo */}
      <Logo />

      {/* Mobile Navbar */}
      {(click && isOpen) && <MobileNavbar close={closeMenuBar} />}

      {/* Desktop Navbar */}
      <div className='navbar'>
        {
          navbar.map((item) => {
            return <HeaderItem key={item.id} {...item} />
          })
        }
      </div>

      {/* Menu Button */}
      <div className='menu-btn' onClick={handleClick}><FaBars /></div>
      
    </nav>
  );
}

export default Header;