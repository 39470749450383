import React, { useState } from 'react';
import './Quantity.css';



const Quantity = ({ quantity }) => {

  const [click, setClick] = useState(quantity);

  // Function to increment the quantity
  const increment = () => {
    setClick(prevClick => parseInt(prevClick) + 1); 
  };

  // Function to decrement the quantity, with a minimum value of 1
  const decrement = () => {
    if (click > 1) {
      setClick(prevClick => parseInt(prevClick) - 1);
    }
  };

  // Function to handle input field changes
  const handleChange = (event) => {
    const newValue = event.target.value;
    setClick(parseInt(newValue));
  };

  return (

    <div className="quantity buttons_added">

      {/* Button to decrement the quantity */}
      <input
        type="button"
        defaultValue="-"
        className="minus"
        onClick={decrement}
      />

      {/* Input field to display and edit the quantity */}
      <input
        type="number"
        step="1"
        min="1"
        max="100"
        name="quantity"
        value={click}
        title="Qty"
        className="input-text qty text"
        onChange={handleChange} 
      />

      {/* Button to increment the quantity */}
      <input
        type="button"
        defaultValue="+"
        className="plus"
        onClick={increment}
      />
      
    </div>
  );
};

export default Quantity;