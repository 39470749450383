const contacts = [  
    { 
        id: 1, 
        title: 'contact',
        content: [
            {id: 1, value: '+123-456-789,'},
            {id: 2, value: '+111-222-333'}
        ],
    },
    {
        id: 2,
        title: 'email',
        content: [
            {id: 1, value: 'abc@gmail.com,'},
            {id: 2, value: 'xyz@gmail.com'}
        ],
    },
    {
        id: 3,
        title: 'address',
        content: [
            {id: 1, value: 'karachi, pakistan'}
        ],
    },
 
];

export default contacts;