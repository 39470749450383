import { FaPlane, FaGlobe, FaHeadphones, FaHotel, FaUtensils, FaTaxi } from 'react-icons/fa'; 


const services = [  
    {  
        id: 1,
        icon: <FaPlane />,
        title: 'Cheap Flights',
        content: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequatur possimus assumenda dolores odit.',
    },
    {  
        id: 2,
        icon: <FaGlobe />,
        title: 'Variety of Tours',
        content: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequatur possimus assumenda dolores odit.',
    },
    {  
        id: 3,
        icon: <FaHeadphones />,
        title: '24/7 Support',
        content: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequatur possimus assumenda dolores odit.',
    },
    {  
        id: 4,
        icon: <FaHotel />,
        title: 'Affordable Hotels',
        content: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequatur possimus assumenda dolores odit.',
    },
    {  
        id: 5,
        icon: <FaUtensils />,
        title: 'Food And Drink',
        content: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequatur possimus assumenda dolores odit.',
    },
    {  
        id: 6,
        icon: <FaTaxi />,
        title: 'Taxi Service',
        content: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequatur possimus assumenda dolores odit.',
    },
];

export default services;