import React, { useState } from 'react';
import './Gallery.css';

import { GalleryItem } from '../../components';
import { galleryContent } from '../../constants';
 
import Lightbox from "react-18-image-lightbox";
import "react-18-image-lightbox/style.css";



const Gallery = () => {

  // State
  const [index, setIndex] = useState(-1);

  // Handlers for lightbox navigation
  const currentImage = galleryContent[index];
  const nextIndex = (index + 1) % galleryContent.length;
  const nextImage = galleryContent[nextIndex] || currentImage;
  const prevIndex = (index + galleryContent.length - 1) % galleryContent.length;
  const prevImage = galleryContent[prevIndex] || currentImage;

  const openLightbox = (index) => setIndex(index);
  const closeLightbox = () => setIndex(-1);
  const handleMovePrev = () => setIndex(prevIndex);
  const handleMoveNext = () => setIndex(nextIndex);

  return (
    <div className='gallery'>

      <div className='box-container'>
        
        {/* Render gallery items */}
        {galleryContent.map((item, index) => {
          return (
            <GalleryItem
              key={item.id}
              id={index}
              image={item.image}
              title={item.title}
              style={item.style}
              popup={openLightbox}
            />
          );
        })}

        {/* Render lightbox when index is not -1 */}
        {index !== -1 && (
          <Lightbox
            mainSrc={currentImage.image}
            imageTitle={currentImage.title}
            mainSrcThumbnail={currentImage.image}
            nextSrc={nextImage.image}
            nextSrcThumbnail={nextImage.image}
            prevSrc={prevImage.image}
            prevSrcThumbnail={prevImage.image}
            onCloseRequest={closeLightbox}
            onMovePrevRequest={handleMovePrev}
            onMoveNextRequest={handleMoveNext}
          />
        )}

      </div>

    </div>
  );
};

export default Gallery;