import React from 'react';
import './DestinationSingle.css';

import { Lightbox, PageTitle, Tour } from '../../../components';

import { FaCoins, FaCalendarAlt, FaChartArea, FaLanguage, FaClock, FaMoneyBillWave } from 'react-icons/fa';
import { useLocation } from "react-router-dom";



const DestinationSingle = () => {  

  const location = useLocation(); 
  const des = location.state;  

  return (   
    <> 
      {/* Page Title */}
      <PageTitle title={'destination single'} page={'destination single'} />
      <section className='destination-single'>

        <div className='des-info' key={des.id}> 

          {/* Destination Intro */}
          <div className='des-intro'>

            {/* Destination Image */}
            <div className='image'>
              <img src={des.image} alt='Destination' />
            </div>

            {/* Destination Details */}
            <div className='destination-details'>
              <div className='container'>
                {/* Duration */}
                <div className='detail-item'>
                  <div className='intro'>
                    <FaClock className='icon' />
                    <h3>ideal duration</h3>
                  </div>
                  <p>{des.duration}</p>
                </div>
                {/* Language */}
                <div className='detail-item'>
                  <div className='intro'>
                    <FaLanguage className='icon' />
                    <h3>language spoken</h3>
                  </div>
                  <p>{des.language}</p>
                </div>
                {/* Currency */}
                <div className='detail-item'>
                  <div className='intro'>
                    <FaCoins className='icon' />
                    <h3>currency</h3>
                  </div>
                  <p>{des.currency}</p>
                </div>
                {/* Best Time */}
                <div className='detail-item'>
                  <div className='intro'>
                    <FaCalendarAlt className='icon' />
                    <h3>best time</h3>
                  </div>
                  <p>{des.bestTime}</p>
                </div>
                {/* Budget */}
                <div className='detail-item'>
                  <div className='intro'>
                    <FaMoneyBillWave className='icon' />
                    <h3>budget</h3>
                  </div>
                  <p>{des.budget}</p>
                </div>
                {/* Area */}
                <div className='detail-item'>
                  <div className='intro'>
                    <FaChartArea className='icon' />
                    <h3>area</h3>
                  </div>
                  <p>{des.area} km<sup>2</sup></p>
                </div>
              </div>
            </div>

          </div>

          {/* Destination Content */}
          <div className='des-content'>
            <h3 className='main-heading'>{des.name}</h3>
            <p>{des.content}</p>
          </div>

          {/* Destination Gallery */}
          <div className='des-gallery'>
            <h3 className='sub-heading'>destination gallery</h3>
            <Lightbox images={des.gallery} className={'des'} /> {/* Destination Gallery Imges */}
          </div>
 
          {/* Destination Tours */}
          <div className='des-tours'>
            <h3 className='sub-heading'>related tours</h3>
            <div className='box-container'>
              {/* Related Tours */}
              {
                (des.tours).map((tour) => {
                  return(  
                    <Tour   
                      key={tour.id} 
                      image={tour.image} 
                      popularity={tour.popularity}
                      reviews={tour.reviews}
                      price={tour.price}
                      name={tour.name}
                      duration={tour.duration}
                      location={tour.location}  
                      tour={tour} 
                    />
                  )
                })
              }
            </div>
          </div>

        </div>

      </section>

    </>

  )
}

export default DestinationSingle;
