import React, { useState } from 'react';
import './ProductSingle.css';

import { Button, Comments, PageTitle, Product, Quantity } from '../../../components';
import { productsContent } from '../../../constants';
import { calculateAverageRating } from '../../../utils/PopularResult';
import { countCommentsAndReplies } from '../../../utils/CommentsLength';

import { FaStar, FaUserFriends, FaListAlt } from 'react-icons/fa';
import { BsPencilSquare } from 'react-icons/bs';
import { Link, useLocation } from 'react-router-dom';
import ImageGallery from 'react-image-gallery'; 

 

const ProductSingle = () => {
  
  const [selectedOption, setSelectedOption] = useState('description');

  const location = useLocation();
  const product = location.state;

  const productComments = product.comments;

  // Calculate the total number of comments and replies for the product
  let totalCommentsAndReplies = 0;
  if (productComments) {
    totalCommentsAndReplies = countCommentsAndReplies(productComments);
  }

  // Calculate the average rating for the product
  const averageRating = calculateAverageRating(product);

  // Handle option change for the product information tabs
  const handleOptionChange = (option) => {
    setSelectedOption(option);
  }; 

  // Get the category titles of the main product
  const mainProductCategoryTitles = product.category.map((cat) => cat.title);

  // Filter the related products based on the same category titles and limit the result to 4
  const filteredRelatedProducts = productsContent
    .filter(
      (relatedProduct) =>
        relatedProduct.category.some((cat) => mainProductCategoryTitles.includes(cat.title)) &&
        relatedProduct.id !== product.id
    )
    .slice(0, 4);

  // Create an array of images for the image gallery
  const image = [
    {
      original: product.gallery.image1,
      thumbnail: product.gallery.image1,
    },
    {
      original: product.gallery.image2,
      thumbnail: product.gallery.image2,
    },
    {
      original: product.gallery.image3,
      thumbnail: product.gallery.image3,
    },
    {
      original: product.gallery.image4,
      thumbnail: product.gallery.image4,
    },
  ];

  const handleSubmit = (event) => {
    event.preventDefault(); // Prevent the default form submission behavior
    // Reset the form fields
    event.target.reset();
  };

  return ( 
    <>
      <PageTitle title={'product single'} page={'product single'} />
      <section className='product-single'>

        <div className='product-des'>

          <div className='product-container'>

            {/* Product Image Gallery */}
            <div className='product-image'>
              <ImageGallery items={image} />
            </div>

            {/* Product Content */}
            <div className='product-content'>
              <div className='text'>
                {/* Product Intro */}
                <div className='product-intro'>
                  <h3>{product.name}</h3> {/* Product Name */}
                  <div className='price'>${product.disprice}/- <span>${product.price}</span></div> {/* Product Price */}
                  <div className='rating'>  {/* Product Rating */}
                    <div className='icon'>            
                      <FaStar /> <FaStar /> <FaStar /> <FaStar /> <FaStar /> 
                    </div> 
                    {/* Render average rating and comment count */}
                    {product.comments.length > 0 ? (
                      <h5>{averageRating} ({product.comments.length})</h5>
                    ) : (
                      <h5>Not rated</h5>
                    )}
                  </div>
                </div>
                <p>{product.content}</p> 
                <div className='box'>
                  {/* Quantity */} 
                  <div className='qty'>
                    <Quantity quantity={1} />  
                  </div>
                  {/* Add to cart button */}
                  <Button link={'cart'} title={'add to cart'} />
                </div>
                <div className='categories'> 
                  <h6>Categories:</h6>
                  {/* Render categories */}
                  {(product.category).map((item) => {
                    return(          
                      <Link
                        key={item.id}
                        to={`/Shop/category/${item.title}`}>
                        {item.title}
                      </Link>
                      ) 
                    })} 
                </div>
                <div className='tags'>
                  <h6>Tags:</h6>
                  {/* Render tags */}
                  {(product.tags).map((item) => {
                    return(                        
                      <Link
                        key={item.id}
                        to={`/Shop/tag/${item.title}`}>
                        {item.title}
                      </Link>
                    )
                  })}
                </div>
              </div>
            </div>

          </div>

          {/* Product More Information */}
          <div className='product-info'>

            {/* Product Information Tab */}
            <div className="product-info-tabs">
              {/* Description Tab */}
              <button
                type="button"
                className={`info-btn ${selectedOption === 'description' ? 'active' : ''}`}
                onClick={() => handleOptionChange('description')}
              >
                <BsPencilSquare /> description
              </button>
              {/* Additional Information Tab */}
              <button
                type="button"
                className={`info-btn ${selectedOption === 'additionalInfo' ? 'active' : ''}`}
                onClick={() => handleOptionChange('additionalInfo')}
              >
                <FaListAlt /> additional information
              </button>
              {/* Reviews Tab */}
              <button
                type="button"
                className={`info-btn ${selectedOption === 'reviews' ? 'active' : ''}`}
                onClick={() => handleOptionChange('reviews')}
              >
                <FaUserFriends /> reviews ({totalCommentsAndReplies})
              </button>
            </div>

            {/* Description Container */}
            {selectedOption === 'description' && (
              <div className="description product-info-gallery">
                
                <p>{product.description}</p>
              </div>
            )}

            {/* Additional Information Container */}
            {selectedOption === 'additionalInfo' && (
              <div className='additional-info product-info-gallery'>
                
                {(product.addInfo).map((item) => {
                  return (
                    <div className='item' key={item.id}>
                      <h3>{item.title}</h3>
                      <span>{item.content}</span>
                    </div>
                  )
                })}
              </div>
            )}

            {/* Reviews Container */}
            {selectedOption === 'reviews' && (
              <div className='reviews product-info-gallery'>
                {/* Reviews */}
                <Comments comments={productComments} />
                <div className='product-review'>
                  {/* Leave a Comment Form */}
                  <form className='form' onSubmit={handleSubmit}>
                    <h3>leave a comment</h3>  
                    <div className='input-box'>
                      <input type="text" name="name" className='box' id="name" placeholder="name" required />
                      <input type="email" name="email" className='box' id="email" placeholder="email" required />
                    </div>
                    <textarea cols="30" rows="10" name="comment" className='box' id="comment" placeholder="comment"></textarea>
                    <button type="submit" name="submit" id="submit" className='btn'>submit</button>
                  </form>
                </div>
              </div>
            )}

          </div>
        </div>

        <div className='related-items'>
          {/* Sidebar Heading */}
          <div className='sidebar-heading'>
            <h2>Related Products</h2>
          </div>

          {/* Product List */}
          <div className='box-container'>
            {
              filteredRelatedProducts.map((product) => {
                return(
                  <Product  
                    key={product.id} 
                    image={product.image}
                    name={product.name}
                    disprice={product.disprice}
                    price={product.price}
                    product={product}
                  />
                )
              })
            }
          </div>
        </div>

      </section>
    </>
  )
}

export default ProductSingle;