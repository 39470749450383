import { FaGlobe, FaAward, FaUsers, FaUsersCog } from 'react-icons/fa';

  
const counter = [
    { 
        id: 1, 
        icon: <FaGlobe />,
        title: 'Amazing Tours',
        count: '50',
    },
    {
        id: 2,
        icon: <FaUsers />,
        title: 'Happy travellers',
        count: '1500',
    },
    {
        id: 3,
        icon: <FaAward />,
        title: 'International Awards',
        count: '25',
    },
    {
        id: 4,
        icon: <FaUsersCog />,
        title: 'Expert Team',
        count: '100',
    },
 
];

export default counter;