
import React, { useEffect, useState } from 'react';
import './ToursPage.css';

import { PageTitle, Tour, PagesNo, SearchTours } from '../../../components';
import { desContent } from '../../../constants';
 
import { useSearchParams, useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';



const ToursPage = () => { 

  const navigate = useNavigate();
  const location = useLocation();

  const [matchingTours, setMatchingTours] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  const itemsPerPage = 6;

  const { search } = useSearchParams();

  // Handle search parameters on page load or when search changes
  useEffect(() => {

    const params = new URLSearchParams(location.search);
    const destinationValue = params.get('destination') || '';
    const durationValue = params.get('duration') || '';
    const maxBudgetValue = params.get('maxBudget') || '';

    const newMatchingTours = handleSearch(destinationValue, durationValue, maxBudgetValue);
    setMatchingTours(newMatchingTours);

    setCurrentPage(1);

  }, [search]);

  // Function to filter tours based on search criteria
  const handleSearch = (destination, duration, maxBudget) => {
    const tours = desContent.flatMap((desContent) => desContent.tours);
    const matchingTours = tours.filter((tour) => {
      const isDestinationMatch = destination === '' || tour.location.toLowerCase().includes(destination.toLowerCase());
      const isDurationMatch = duration === '' || tour.duration === duration;
      const isBudgetMatch = maxBudget === '' || tour.price <= maxBudget;
      return isDestinationMatch && isDurationMatch && isBudgetMatch;
    });
    return matchingTours;
  };

  // Handle form submission and update search parameters
  const handleFormSubmit = (destination, duration, maxBudget) => {
    const params = new URLSearchParams();
    params.set('destination', destination);
    params.set('duration', duration);
    params.set('maxBudget', maxBudget);
    navigate(`/Tours?${params.toString()}`);

    const newMatchingTours = handleSearch(destination, duration, maxBudget);
    setMatchingTours(newMatchingTours);

    setCurrentPage(1);
 
  }; 

  // 'tours' array: The array of all tours from the 'desContent' data
  // 'displayTours' array: The array of tours to be displayed, either the matching tours or all tours if no search criteria is applied
  const tours = desContent.flatMap((desContent) => desContent.tours);
  const displayTours = matchingTours.length > 0 ? matchingTours : tours;

  // 'startIndex' and 'endIndex': The start and end indices for the current page's range of displayed tours
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  // 'displayedTours' array: The subset of tours to be displayed on the current page
  // 'totalPages': The total number of pages based on the number of tours and items per page
  const displayedTours = displayTours.slice(startIndex, endIndex);
  const totalPages = Math.ceil(displayTours.length / itemsPerPage);

  // Updates the current page when a page number is selected
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  
  return (
    <>
      {/* Page title */}
      <PageTitle title={'tours'} page={'tours'} />

      {/* Search tours component */}
      <SearchTours onFormSubmit={handleFormSubmit} />

      <section className="tours">
        {/* Check if there are matching tours */}
        {matchingTours.length === 0 ? (
          // Render "No tours found" message if no matching tours
          <div className='no-tour-found'>No tours found.</div>
        ) : (
          <>
            <div className="box-container">
              {/* Map over displayedTours and render each tour component */}
              {displayedTours.map((tour, index) => {
                return (
                  <Tour  
                    key={index}
                    image={tour.image} 
                    price={tour.price}
                    name={tour.name}
                    duration={tour.duration}
                    location={tour.location}  
                    tour={tour} 
                  />
                )
              })}
            </div>
            {/* pagination component */}
            <PagesNo currentPage={currentPage} totalPages={totalPages} handlePageChange={handlePageChange} />
          </>
        )}
      </section>
    </>

  );
};

export default ToursPage;