import React, { useState } from 'react';
import './SearchTours.css';

import Select from 'react-select';

// Array of destination options
const destinations = [
  { value: '', label: 'Select destination', isDisabled: true },
  { value: 'spain', label: 'Spain' },
  { value: 'uae', label: 'UAE' }, 
  { value: 'indonesia', label: 'Indonesia' },
  { value: 'greece', label: 'Greece' },
  { value: 'italy', label: 'Italy' },
  { value: 'thailand', label: 'Thailand' },
  { value: 'egypt', label: 'Egypt' },
  { value: 'mexico', label: 'Mexico' },
];

// Array of duration options
const durations = [
  { value: '', label: 'Select duration', isDisabled: true },
  { value: '3 days', label: '3 days' },
  { value: '4 days', label: '4 days' },
  { value: '5 days', label: '5 days' },
];

const SearchTours = ({ onFormSubmit }) => {
  
  // State variables
  const [destinationValue, setDestinationValue] = useState('');
  const [durationValue, setDurationValue] = useState('');
  const [maxBudgetValue, setMaxBudgetValue] = useState('');

  // Event handler for destination change
  const handleDestinationChange = (selectedOption) => {
    setDestinationValue(selectedOption ? selectedOption.value : '');
  };

  // Event handler for duration change
  const handleDurationChange = (selectedOption) => {
    setDurationValue(selectedOption ? selectedOption.value : '');
  };

  // Event handler for max budget change
  const handleMaxBudgetChange = (e) => {
    setMaxBudgetValue(e.target.value);
  };

  // Event handler for form submission
  const handleFormSubmit = (e) => {
    e.preventDefault();
    if (destinationValue || durationValue || maxBudgetValue) {
      onFormSubmit(destinationValue, durationValue, maxBudgetValue);
    }
    setDestinationValue('');
    setDurationValue('');
    setMaxBudgetValue('');
  };

  return (
    <section className="searchTour">
      <form className="search-tour" onSubmit={handleFormSubmit}>
        {/* Destination select input */}
        <Select
          options={destinations}
          value={destinations.find((option) => option.value === destinationValue)}
          onChange={handleDestinationChange}
          className="custom-select box"
          classNamePrefix="custom-select-prefix"
        />

        {/* Duration select input */}
        <Select
          options={durations}
          value={durations.find((option) => option.value === durationValue)}
          onChange={handleDurationChange}
          className="custom-select box"
          classNamePrefix="custom-select-prefix"
        />

        {/* Max budget input */}
        <div className="box">
          <input
            type="number"
            id="price"
            name="price"
            placeholder="max price"
            value={maxBudgetValue}
            onChange={handleMaxBudgetChange}
            className="input-field"
            min="0"
          />
        </div>

        {/* Submit button */}
        <div className="box">
          <button type="submit" className="btn">search</button>
        </div>
      </form>
    </section>
  );
};

export default SearchTours;