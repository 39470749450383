/* Counts the number of comments and their replies recursively. */
export function countCommentsAndReplies(comments) {
  
  let count = 0;

  comments.forEach(comment => {
    count++; // add 1 for the comment itself
    if (comment.replies && comment.replies.length > 0) {
      count += countCommentsAndReplies(comment.replies); // add count of replies recursively
    }
  });

  return count; 

} 