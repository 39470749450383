import React, { useState } from 'react';
import './BlogSingle.css';

import { BlogSidebar, Comments, Lightbox, PageTitle } from '../../../components';
import { blogContent } from '../../../constants';
import { useBlogFilter } from '../../../utils/BlogUtils'; 

import { FaFacebookF, FaTwitter, FaInstagram, FaLinkedin } from 'react-icons/fa';
import { FaUserCircle, FaCalendarAlt, FaQuoteLeft } from 'react-icons/fa';
import { Link, useLocation } from "react-router-dom";



const BlogSingle = () => { 
  const { 
    selectedCategory,
    selectedTag,
    handleSearchSubmit,
  } = useBlogFilter(blogContent);

  const location = useLocation();
  const blog = location.state;

  const paragraphs = blog.paragraph;
  const blogComments = blog.comments;

  const handleSubmit = (event) => {
    event.preventDefault(); // Prevent the default form submission behavior
    // Reset the form fields
    event.target.reset();
  };

  return (
    <>
      {/* Page title */}
      <PageTitle title={'blog single'} page={'blog single'} />

      <section className='blog-single'>
        <div className='blog-container'>
          <div className='blog-info' key={blog.id}>

            {/* Blog Image */}
            <div className='image'>
              <img src={blog.image} alt='Blog' />
            </div>

            <div className='content'>
              <div className='details'>
                {/* Blog category */}
                <Link
                  className='category'
                  key={blog.id}
                  to={`/Blogs/category/${blog.category}`}
                >
                  {blog.category}
                </Link>

                {/* Blog author and date */}
                <h3>
                  <FaUserCircle className='icon' />
                  <span>By {blog.admin}</span>
                </h3>
                <h3>
                  <FaCalendarAlt className='icon' />
                  <span>{blog.date}</span>
                </h3>
              </div> 

              {/* Blog heading */}
              <h3 className='main-heading'>{blog.heading}</h3>

              {/* Blog paragraphs */}
              <p>{paragraphs.para1}</p>
              <p>{paragraphs.para2}</p>

              {/* Important quote */}
              <div className='important'>
                <FaQuoteLeft className='icon' />
                {blog.important}
              </div>

              <p>{paragraphs.para3}</p>
              <p>{paragraphs.para4}</p>

              {/* Blog image gallery */}
              <Lightbox images={blog.gallery} className={'blog'} />

              <div className='end-details'>
                {/* Blog tags */}
                <div className='tags'>
                  {blog.tags.map((item) => (
                    <Link
                      className='item'
                      key={item.id}
                      to={`/Blogs/tag/${item.title}`}
                    >
                      {item.title}
                    </Link>
                  ))}
                </div>

                {/* Share buttons */}
                <div className='share'>
                  <h3>share:</h3>
                  <FaFacebookF className='icon' />
                  <FaLinkedin className='icon' />
                  <FaTwitter className='icon' />
                  <FaInstagram className='icon' />
                </div>
              </div>
            </div>
          </div>

          {/* Comments section */}
          <Comments comments={blogComments} />

          {/* Leave a comment */}
          <div className='leave-reply'>
            <form className='form' onSubmit={handleSubmit}>
                <h3>leave a comment</h3>  
                <div className='input-box'>
                    <input type="text" name="name" className='box' id="name" placeholder="name" required />
                    <input type="email" name="email" className='box' id="email" placeholder="email" required />
                </div>
                <textarea cols="30" rows="10" name="comment" className='box' id="comment" placeholder="comment"></textarea>
                <button type="submit" name="submit" id="submit" className='btn'>submit</button>
            </form>
          </div>

        </div>

        {/* Blog Sidebar */}
        <BlogSidebar
          selectedCategory={selectedCategory}
          selectedTag={selectedTag}
          handleSubmit={handleSearchSubmit}
        /> 

      </section>

    </>

  )
}

export default BlogSingle;