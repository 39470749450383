import React from 'react';
import './Tour.css';

import { calculateAverageRating } from '../../utils/PopularResult';

import { BsMap, BsCalendar } from 'react-icons/bs';
import { FaStar } from 'react-icons/fa';
import { Link } from 'react-router-dom';



const Tour = (props) => {

  const { id, image, price, name, duration, location, tour } = props;

  // Calculate the average rating of the tour 
  const averageRating = calculateAverageRating(tour);

  return (
    <div className='tour-item' key={id}>
      
      {/* Tour image container */}
      <div className='image'>
        <img src={image} alt='Tour' /> {/* Tour image */}
        <div className='price'>${price}</div> {/* Tour price */}
      </div>

      {/* Tour content */}
      <div className='content'>
        {/* Tour rating */}
        <div className='rating'>
          <div className='icon'>
            {/* Display star icons for rating */}
            <FaStar /> <FaStar /> <FaStar /> <FaStar /> <FaStar />
          </div>
          {/* Display average rating and number of comments */}
          {tour.comments.length > 0 ? (
            <h5>{averageRating} ({tour.comments.length})</h5>
          ) : (
            <h5>Not rated</h5>
          )}
        </div>

        {/* Tour name */}
        <Link
          to={{
            pathname: '/Tours/' + name,
          }}
          state={tour}
        >
          {name}
        </Link>

        {/* Tour details */}
        <div className='details'>
          {/* Tour duration */}
          <h3>
            <BsCalendar className='icon' />
            <span>{duration}</span>
          </h3>
          {/* Tour location */}
          <h3>
            <BsMap className='icon' />
            <span>{location}</span>
          </h3>
        </div>
      </div>
    </div>
  );
}

export default Tour;